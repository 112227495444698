@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./variable";

:root {
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --blackClr: #111;
  --whiteClr: #fff;
  --primaryClr: #ffdd00;
  --headClr: #023f6e;
  --bodyClr: #4e4e4e;
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --tertiory1: #ffffff !important;
}

body::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}
.sports_page .tab-content .tab-pane::-webkit-scrollbar {
  background: orange;
  /* color of the tracking area */
}
.sports_page .tab-content .tab-pane::-webkit-scrollbar {
  background-color: #c2c2c2;
  border-radius: 20px;
  border: 3px solid #c2c2c2;
}
body::-webkit-scrollbar {
  background: orange;
  /* color of the tracking area */
}

body::-webkit-scrollbar {
  background-color: #0a0f19;
  border-radius: 20px;
  border: 3px solid #0a0f19;
}

html,
body {
  background: var(--bodyClr);
  color: var(--blackClr);
  font-size: 14px;
  // font-family: "Montserrat", sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  //   "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family: "Helvetica Neue", Helvetica, Tahoma, "PingFang SC",
    "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif !important;
  line-height: 20px;
  .loading_img {
    background: var(--secondary2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
.btn {
  text-decoration: none;
}

button:focus,
.btn:focus {
  outline: 0;
}

input:focus,
input.form-control:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn_primary {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
  border: 1px solid var(--primaryClr);
  border-radius: 2rem;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 0.9;
  }
}

.secondaryBtn,
.btn_secondary {
  background: var(--primaryClr) !important;
  color: var(--whiteText2);
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif;
  letter-spacing: 0.8px;
  height: 2.95em;
  line-height: 2.95em;
  margin-left: 8px;
  padding: 0 2em;
  border: 0;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_outline {
  background: transparent;
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid var(--primaryClr);
  border-radius: 2rem;
  opacity: 0.9;
  transition: all 0.2s;
  &:hover {
    background: var(--primaryClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 1;
  }
}

.signup_btn {
  background: linear-gradient(
    to right,
    #facd04 0,
    #fbc103 15%,
    #fbc103 30%,
    #facd04 55%,
    #facd04 100%
  );
  color: var(--whiteClr);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  height: 30px;
  padding: 0 1.875rem;
  border: 1px solid transparent !important;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: 0 5px 10px 0 rgba(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
  &:hover {
    background: linear-gradient(
      to right,
      #facd04 0,
      #fbc103 15%,
      #fbc103 30%,
      #facd04 55%,
      #facd04 100%
    ) !important;
    text-shadow: 0 1px 3px #967b02;
    box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
      0 4px 4px 1px rgba(6, 8, 15, 0.1), 0 8px 8px 1px rgba(6, 8, 15, 0.1),
      0 16px 16px 1px rgba(6, 8, 15, 0.1), 0 32px 32px 1px rgba(6, 8, 15, 0.1),
      inset 0 0 3px 0#ffdd8f;
  }
}
.special-label {
  display: none;
}
.login_btn {
  background: transparent;
  color: var(--whiteClr);
  font-size: 15px;
  font-weight: 400;
  height: 30px;
  text-align: left;
  padding: 0 1.875rem;
  margin-right: 0.625rem;
  transition: all 0.3s;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  &:hover {
    background: transparent !important;
    // color: var(--blackClr) !important;
    border-color: #013d72 !important;
  }
}

.form-control,
.form-control:focus {
  background: var(--whiteClr) !important;
  color: #331a09 !important;
  border-radius: 0 !important;
  &.is-invalid {
    box-shadow: none;
  }
  &::placeholder {
    color: #331a09;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #454545 inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
  border-color: #454545 !important;
}

.container {
  @media (min-width: 1200px) {
    max-width: 1480px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    max-width: 1180px;
  }
}
.custom-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  @media (min-width: 1200px) {
    width: 1200px;
  }
  @media (max-width: 1199.98px) and (min-width: 768px) {
    width: calc(100% - 30px);
  }
}

body {
  .header_bfrlgn {
    .theme-btn {
      width: 25px;
      margin: 0;
      .btn {
        width: 25px;
        height: 25px;
        padding: 3px;
        img {
          width: 16px;
          &.dark_theme {
            display: none;
          }
        }
        &:after {
          content: none;
        }
      }
    }
  }
  .theme-btn {
    width: 80px;
    margin: 100px auto 20px;
    display: none;
    .btn {
      background: var(--tertiory1);
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      border-radius: 25px;
      position: relative;
      &:hover {
        background: var(--tertiory1);
      }
      img {
        width: 20px;
      }
      &:after {
        position: absolute;
        content: "";
        top: 4px;
        left: 0px;
        transform: translateX(5px);
        width: 30px;
        height: 30px;
        background: var(--primaryClr);
        border-radius: 50%;
        transition: all 0.3s;
      }
    }
  }
  &.light-theme {
    .header_bfrlgn {
      img {
        &.dark_theme {
          display: block !important;
        }
        &.light_theme {
          display: none;
        }
      }
    }
    .theme-btn {
      .btn {
        &:after {
          transform: translateX(40px);
        }
      }
    }
  }
}

.header {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  .top_head {
    background: #2d2d2d;
    height: 30px;
    border-bottom: 1px solid #0078c1;
    position: relative;
    z-index: 999;
    .top-content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .time-lang {
      height: 100%;
      display: flex;
      align-items: center;
      .time {
        color: var(--whiteClr);
        font-size: 14px;
        margin-right: 0.625rem;
      }
      .lang {
        background: rgba(85, 85, 85, 0.9);
        display: flex;
        align-items: center;
        padding: 0 10px;
        img {
          width: 23px;
          height: auto;
          margin-right: 5px;
          box-shadow: 0 0 3px rgba(9, 13, 26, 0.4);
        }
        p {
          color: var(--whiteClr);
          font-size: 14px;
          width: 110px;
          height: 29px;
          line-height: 29px;
          margin-bottom: 0;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .social-contact {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 7px;
        }
        a {
          img {
            width: 15px;
            height: auto;
            opacity: 0.8;
          }
          span {
            color: var(--whiteClr);
            padding: 0 7px 0 5px;
            opacity: 0.7;
          }
        }
      }
    }
  }
  .middle_head {
    height: 70px;
    line-height: 70px;
    position: relative;
    z-index: 999;
    @media (min-width: 992px) {
      background: linear-gradient(to bottom, #0088da 0, #013d72 100%);
      border-bottom: 1px solid #0078c1;
    }
    @media (max-width: 991.98px) {
      background: var(--blackClr);
      height: 13.3333333333vw;
      line-height: 13.3333333333vw;
    }
    @media (max-width: 767.98px) {
      padding: 0 0 0 15px;
    }
    .login-buttons {
      @media (max-width: 991.98px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 13.3333333333vw;
        display: flex;
        box-shadow: 0 0 1.3333333333vw #00000080;
        .btn {
          font-size: 4vw;
          font-weight: 700;
          text-align: center;
          width: 50%;
          height: 100%;
          margin: 0;
          border: 0 !important;
          border-radius: 0;
          &.login_btn {
            order: 1;
            background: #0088da;
          }
          &.signup_btn {
            order: 0;
            color: var(--blackClr);
            text-transform: none;
          }
        }
      }
    }
    .apk-help {
      display: flex;
      align-items: center;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 12vw;
        max-width: 17.3333333333vw;
        img {
          width: 5.3333333333vw;
          height: 5.3333333333vw;
          margin-bottom: 0.5333333333vw;
        }
        span {
          color: #0088da;
          font-size: 3.2vw;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  .Bottom_head {
    background-color: #3e3e3e;
    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      @media (max-width: 767.98px) {
        overflow-y: hidden;
      }
      li {
        flex-grow: 1;
        min-width: 7.25rem;
        transition: all 0.3s;
        a {
          color: var(--whiteClr);
          font-size: 14px;
          font-weight: 400;
          height: 100%;
          line-height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 0 0.625rem;
          white-space: nowrap;
          position: relative;
          transition: all 0.3s;
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            transition: height 0.5s ease-out 0.1s, opacity 0.5s ease-in;
            opacity: 0;
            background: url("./assets/images/nav-btm-light.png") no-repeat
              center bottom;
            background-size: 100% 100%;
          }
          &::after {
            position: absolute;
            content: "";
            top: 16px;
            right: 0;
            bottom: auto;
            left: auto;
            width: 1px;
            height: 13px;
            background: var(--whiteClr);
          }
          .icon-angle-down {
            display: inline-block;
            width: 11px;
            height: 10px;
            transition: background 0.3s;
            background: #fff;
            margin-left: 5px;
            mask: url("./assets/images/icons/icon-angle-down.png") no-repeat
              center / contain;
          }
          &:hover,
          &.active {
            background: rgba(0, 0, 0, 0.1);
            color: #41b8ff;
            .icon-angle-down {
              background: #0088da;
              mask: url("./assets/images/icons/icon-angle-down.png") no-repeat
                center / contain;
            }
          }
        }
        &.home-app {
          flex: 0 0 45px;
          min-width: auto;
          .icon {
            background: #fff;
            width: 1.25rem;
            height: 1.25rem;
            &.home {
              mask: url("./assets/images/icons/home-icon.svg") no-repeat center /
                contain;
            }
            &.app {
              mask: url("./assets/images/icons/app-icon.svg") no-repeat center /
                contain;
            }
          }
          a {
            &:hover,
            &.active {
              .icon {
                background: #0088da;
                &.home {
                  mask: url("./assets/images/icons/home-icon.svg") no-repeat
                    center / contain;
                }
                &.app {
                  mask: url("./assets/images/icons/app-icon.svg") no-repeat
                    center / contain;
                }
              }
            }
          }
        }
        &:not(.home):hover {
          a {
            &::before {
              bottom: 0;
              height: 10px;
              transition: bottom 0.2s ease-in, opacity 0.3s ease-out;
              border-bottom: 2px solid #ffdf18;
              opacity: 1;
            }
          }
        }
        &.sports_menu {
          position: relative;
          .sports-games {
            position: absolute;
            top: 45px;
            left: 0;
            right: 0;
            width: 100vw;
            height: auto;
            background: #313131;
            margin-left: calc((100vw - 1200px) / -2 - 45px); //45px is 1st li w
            transform: translateY(-200%);
            transition: all 0.3s;
            @media (min-width: 992px) and (max-width: 1199.98px) {
              margin-left: -60px; //15px parent cntnr ml and 45px is 1st li w
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              z-index: 2;
              bottom: 0;
              width: 100%;
              height: 32px;
              background: #252525;
            }
            .slick-slider {
              @media (min-width: 1200px) {
                width: 1200px;
                margin: 0 auto;
              }
              .slick-list {
                max-width: calc(100% - 100px);
                margin: 0 auto;
              }
              .slick-arrow {
                width: 28px;
                height: 60px;
                background: #ffdf18;
                transition: transform 0.3s ease, -webkit-transform 0.3s ease;
                mask: url("./assets/images/icon-prev.svg") no-repeat center /
                  70%;
                &::before {
                  content: none;
                }
                &.slick-prev {
                  left: -4px !important;
                  transform: translate(0, -50%);
                  @media (max-width: 1219.98px) {
                    left: 8px !important;
                  }
                  &:hover {
                    transform: translate(0, -50%) scale(1.2);
                  }
                }
                &.slick-next {
                  right: -4px !important;
                  transform: translate(0, -50%) rotate(180deg);
                  @media (max-width: 1219.98px) {
                    right: 8px !important;
                  }
                  &:hover {
                    transform: translate(0, -50%) rotate(180deg) scale(1.2);
                  }
                }
              }
            }
            h5 {
              color: var(--whiteClr);
              padding-left: 12px;
              margin-top: 20px;
              margin-bottom: 0;
              position: relative;
              &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                width: 2px;
                height: 18px;
                background: #f5d13c;
              }
            }
            a {
              padding: 0;
              margin-top: -33px;
              &::after,
              &::before {
                content: none;
              }
              &:hover {
                background: transparent;
              }
            }
          }
        }
        &:hover {
          .sports-games {
            transform: translateY(0);
          }
        }
      }
    }
  }
  .searchbar {
    background-color: #005ca2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: var(--whiteClr);
      font-size: 20px;
    }
  }
  .menu-btn {
    background: transparent;
    border: 0 !important;
    &:hover,
    &:active,
    &:focus-visible {
      background: transparent;
      outline: 0;
      box-shadow: none !important;
    }
    img {
      @media (max-width: 991.98px) {
        width: 6.3333333333vw;
        height: 6.3333333333vw;
      }
    }
  }
  .logo {
    width: 150px;
    margin: 0 1.5625rem 0 1.25rem;
    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: 120px;
    }
    @media (min-width: 576px) and (max-width: 991.98px) {
      width: 100px;
    }
    @media (max-width: 575.98px) {
      width: 80px;
      margin: 0 0 0 0.75rem;
    }
    @media (max-width: 359.98px) {
      width: 70px;
    }
    a {
      display: block;
      @media (max-width: 991.98px) {
        width: 25.333333vw !important;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .nav {
    .nav-link {
      color: var(--tertiory1);
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      display: block;
      padding: 3px;
      margin: 0 4px;
      position: relative;
      &:hover,
      &:focus,
      &.active {
        color: var(--primaryClr);
      }
      &::after {
        position: absolute;
        content: "";
        bottom: 50%;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: -1;
      }
      &:hover::after,
      &:focus::after,
      &.active::after {
        bottom: -2px;
        background: var(--primaryClr) !important;
        opacity: 1;
        visibility: visible;
        z-index: 9;
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--textClr);
    .path-stroke {
      stroke: var(--textClr);
    }
    ul {
      display: flex;
      align-items: center;
      li {
        color: var(--tertiory1);
        line-height: 25px;
        position: relative;
        margin-right: 12px;
        &.deposit {
          a {
            background: linear-gradient(
              to right,
              #facd04 0,
              #fbc103 15%,
              #fbc103 30%,
              #facd04 55%,
              #facd04 100%
            );
            color: var(--whiteClr);
            font-size: 15px;
            height: 30px;
            line-height: 30px;
            display: block;
            padding: 0 1.25rem;
            box-shadow: 0 5px 10px 0 rgba(6, 8, 15, 0.1),
              inset 0 0 3px 0 #ffdd8f;
            border-radius: 3px;
            &:hover {
              text-shadow: 0 1px 3px #967b02;
              box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
                0 4px 4px 1px rgba(6, 8, 15, 0.1),
                0 8px 8px 1px rgba(6, 8, 15, 0.1),
                0 16px 16px 1px rgba(6, 8, 15, 0.1),
                0 32px 32px 1px rgba(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
            }
          }
        }
        img {
          width: 22px;
          height: 22px;
          max-width: 22px;
        }
        .deposit-header {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
        }
        .notification-count {
          background: var(--whiteClr);
          color: var(--headClr);
          font-weight: 600;
          border-radius: 25px;
          padding: 0 5px;
          width: 116px;
          height: 35px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .balance-amount {
          color: var(--whiteClr);
          position: relative;
          .WithDrawHeader {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 1px solid var(--whiteClr);
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid var(--whiteClr) !important;
            color: var(--whiteClr);
          }
          &::before {
            position: absolute;
            content: "";
            width: 2px;
            height: 20px;
            display: none;
            background-color: var(--headClr);
            transform: translate(-50%, -50%);
            top: 50%;
            left: 57%;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;

      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }

      svg {
        width: 21px;
        height: auto;
        fill: var(--textClr);
      }
    }
  }
}
.light-theme {
  .logo,
  .footer-logo {
    width: 40%;

    img {
      width: 18%;
      &.light_theme {
        visibility: visible;
        width: auto;
      }
      &.dark_theme {
        visibility: hidden;
        width: 0;
      }
    }
  }
}
.light-theme {
  .header {
    .headerRight {
      ul {
        li {
          &:not(.balance) {
            img {
              filter: none;
            }
          }
        }
      }
    }
  }
}

.back-link {
  background: transparent;
  color: var(--whiteClr);
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: max-content;
  line-height: 20px;
  margin-top: 0;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.3s;
  border: 1px solid var(--primaryClr);
  svg {
    margin-left: 0.5rem;
    transition: all 0.2s;
  }
  &:hover,
  &:focus {
    background: #facd04;
    color: var(--whiteClr);
    text-shadow: 0 1px 3px #967b02;
    box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
      0 4px 4px 1px rgba(6, 8, 15, 0.1), 0 8px 8px 1px rgba(6, 8, 15, 0.1),
      0 16px 16px 1px rgba(6, 8, 15, 0.1), 0 32px 32px 1px rgba(6, 8, 15, 0.1),
      inset 0 0 3px 0 #ffdd8f;
  }
}

.offcanvas {
  border: none !important;
  z-index: 999999999 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
}

.offcanvas-header {
  background: var(--innerPagesHeadBG);
  color: var(--innersec--headClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--headClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--myProfileHeadText);
    svg {
      fill: gold;
    }
  }
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user_name,
    p {
      color: var(--whiteClr);
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
    &:focus {
      box-shadow: none;
    }
  }
}

.light-theme {
  .offcanvas {
    .btn-close-white {
      filter: invert(1) !important;
    }
  }
}

.offcanvas-body {
  background: var(--headClr);
  padding: 1rem 3rem;

  .balance {
    color: var(--tertiory1);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
      width: 24px;
    }
  }
  .balance-amount {
    color: var(--tertiory1);
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }

  .menu-items {
    margin-top: 2rem;
    padding-left: 2rem;

    li {
      a {
        display: flex;
        align-items: center;
        padding: 12px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        font-size: 16px;
        font-weight: 400;
        svg,
        img {
          margin-right: 15px;
          width: 26px;

          height: auto;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .deposit_btn {
    background: #ff422e;
    color: var(--whiteText);
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primaryClr);
    border-top-color: rgb(252, 194, 0);
    border-right-color: rgb(252, 194, 0);
    border-bottom-color: rgb(252, 194, 0);
    border-left-color: rgb(252, 194, 0);
    border-radius: 2rem;
    opacity: 0.9;
    transition: all 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      background: #ff422e;
      opacity: 0.8;
    }
  }
}

.form-steps {
  text-align: center;
}

.main {
  min-height: 100vh;
  padding-top: 145px;
  padding-bottom: 30px;
  @media (max-width: 991.98px) {
    padding-top: 13.3333333333vw;
  }
  .pages_top_head {
    // background: var(--blackClr);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 4px;
    // padding: 8px 10px;
    h5 {
      color: var(--whiteClr);
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 18px;
        margin-right: 10px;
        background: #0088da;
        vertical-align: middle;
      }
    }
  }
  .TopCategories {
    margin-bottom: 20px;
    border-top: 1px solid #d2d7ea;
    border-bottom: 1px solid #d2d7ea;
    ul {
      display: flex;
      margin: 0;
      list-style: none;
      overflow-x: auto;
      li {
        text-align: center;
        min-width: 80px;
        a {
          color: var(--primaryClr);
          font-size: 1rem;
          font-weight: 400;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          .IconHolder {
            border-radius: 5px;
            padding: 5px 8px 0 8px;
            width: 60px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span {
            padding: 3px 0 2px;
            white-space: nowrap;
          }
        }

        &.active {
          background: var(--primaryClr);

          a {
            color: var(--whiteText);
          }
        }
      }
    }
  }

  .mainBanner {
    .slick-slide {
      .content {
        position: absolute;
        left: 0;
        width: 60%;
        padding: 10px 0;
        margin: auto;
        h2 {
          color: var(--whiteText);
          font-size: 3rem;
          max-width: 380px;

          span {
            color: var(--primaryClr);
          }
        }
        p {
          color: var(--whiteText);
          font-size: 1.1rem;
          max-width: 350px;
        }
      }
      img {
        width: 100%;
        border-radius: 1rem;
        @media (min-width: 768px) {
          max-height: 620px;
          object-fit: cover;
        }
      }
    }

    .slick-dots {
      bottom: -15px;
      li {
        width: 10px;
        height: 10px;
        margin: 0 0.25rem;
        button {
          width: 10px;
          height: 10px;
          background: var(--tertiory1);
          padding: 0;
          opacity: 0.5;
          border-radius: 50%;
          &::before {
            display: none;
          }
        }
        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  .Live_casino_tabs {
    .nav-tabs {
      background: #1c2634;
      flex-wrap: nowrap;
      overflow-x: auto;
      border-bottom: 0;
      .nav-item {
        .nav-link {
          background-image: ("./public/images/game-image/Roulette_Icons.png");
          color: var(--whiteText);
          font-size: 14px;
          background-repeat: no-repeat;
          background-repeat: no-repeat;
          background-position: 36px 10px;
          background-size: 50px 50px;
          padding: 65px 5px 5px 5px;
          width: 125px;
          height: 100%;
          border: 0;
          border-radius: 0px !important;
          &:hover,
          &.active {
            color: var(--primaryClr);
            border: 0;
            border: 1px solid #daa52000;
          }
          &.active {
            background-image: ("./public/images/Roulette_Icons.png");
          }
        }
      }
    }

    .top_Sec {
      display: grid;
      grid-gap: 1.5rem 0.75rem;
      grid-template-columns: repeat(4, 1fr);
      padding: 1rem 0.75rem;
    }
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--headClr);
    position: relative;
    display: inline-block;
    margin: 25px 0;
    padding-bottom: 5px;
    &:after {
      content: " ";
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 70%;
      height: 2px;
      background: var(--primaryClr);
    }
  }

  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px #0f121a;

    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteText);
      text-transform: uppercase;
      padding: 8px;
    }
  }

  .payment-info {
    background: #313131;
    color: var(--whiteClr);
    padding: 20px;
    margin-top: 15px;
    @media screen and (max-width: 575.98px) {
      margin-right: calc(var(--bs-gutter-x) * -0.5);
      margin-left: calc(var(--bs-gutter-x) * -0.5);
    }
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: #fff;
        stroke: #fff;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      border-radius: 5px;
      border-color: var(--whiteClr);
      color: var(--whiteClr);
      &.btn-outline-secondary {
        border-color: var(--whiteText);
        color: var(--whiteText);
      }
      &.btn_withdraw {
        color: var(--whiteClr);
        width: 100%;
        border-color: var(--whiteText2);
      }
      &.btn_primary {
        background: linear-gradient(
          to right,
          #facd04 0,
          #fbc103 15%,
          #fbc103 30%,
          #facd04 55%,
          #facd04 100%
        );
        color: var(--whiteClr);
        width: 100%;
        border-color: var(--primaryClr);
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(
            to right,
            #facd04 0,
            #fbc103 15%,
            #fbc103 30%,
            #facd04 55%,
            #facd04 100%
          ) !important;
          color: var(--whiteClr) !important;
          text-shadow: 0 1px 3px #967b02;
          box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
            0 4px 4px 1px rgba(6, 8, 15, 0.1), 0 8px 8px 1px rgba(6, 8, 15, 0.1),
            0 16px 16px 1px rgba(6, 8, 15, 0.1),
            0 32px 32px 1px rgba(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
        }
      }
    }
  }
  .account-statement {
    input {
      z-index: 9 !important;
      background-color: transparent !important;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      background: #313131;
      border-color: #313131;
      height: 32px;
      border-radius: 0;
    }
    h3 {
      font-size: 16px;
      margin: 20px 5px;
      padding: 0 1rem 12px 1rem;
      color: var(--headClr);
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      li {
        padding: 22px 1rem;
        border-bottom: 2px solid #fff;
        .h4 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
          color: var(--headClr);
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
          color: var(--headClr);
        }
        .details {
          font-size: 14px;
          font-weight: 500;
          span:last-child {
            color: red;
            cursor: pointer;
          }
        }
        .statue {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.7px;
          background: #dcdcdc;
          padding: 3px;
          border-radius: 3px;
        }
        small {
          margin-top: 7px;
          font-size: 12px;
          font-weight: 300;
          display: block;
          color: #3d4244;
        }
        span {
          color: #3d4244;
        }
      }
    }
    table {
      thead {
        background: #a3a3a3;
        tr {
          th {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
      tbody {
        background: #7b7b7b;
        color: #fff;
        tr {
          td {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 160px;
      width: 160px;
      height: 160px;
      border-radius: 10%;
      border: 2px solid var(--primaryClr);
    }
    span {
      color: var(--whiteClr);
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .edit-btn {
    background: var(--primaryClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteText);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-info {
    background: var(--headClr);
    th {
      background: var(--headClr);
      color: var(--whiteClr);
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .form-control {
      background: var(--lytThemeClr1);
      color: var(--whiteText);
      border-color: var(--primaryClr);
    }

    .dropdown-toggle {
      background: var(--headClr) !important;
      border-radius: 0;
      padding: 5px 15px;
      border: 1px solid var(--primaryClr) !important;
      color: var(--tertiory1);
      width: 100%;
      text-align: left;
      position: relative;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      position: absolute;
      right: 10px;
      top: 50%;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      color: var(--tertiory1);
      text-decoration: none;
      background: var(--headClr) !important;
      border-radius: 0;
    }
    .btn-outline-primary {
      padding: 5px 10px;
      margin-left: 2px;
      background: #252a3a;
      border-color: var(--primaryClr);
      border-radius: 0 !important;
    }
    .dropdown-menu {
      background: var(--headClr);
      border-radius: 0;
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -2px;
      a {
        color: var(--tertiory1);
        font-size: 14px;
        padding: 0.75rem 1rem;
        margin: 0;
        border: 0;
        border-bottom: 1px solid var(--secondaryBdr);
        &:last-child {
          border: none;
        }
        &:hover {
          background: transparent !important;
          color: rgba(38, 50, 75, 0.8) !important;
          border: 0 !important;
          border-bottom: 1px solid rgba(232, 232, 232, 1) !important;
        }
      }
    }
  }

  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;

    svg {
      fill: var(--whiteText);
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    .offcanvas-title {
      color: #5d520a;
      font-size: 19px;
      font-weight: 700;
    }
  }
  .form-steps {
    padding: 5px 0;
    border-radius: 0.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .favicon {
      margin-bottom: 12px;
    }

    .sportsSection {
      display: none;
      width: 120px;
    }
    .h2 {
      color: var(--tertiory1);
      font-size: 15px;
      margin-bottom: 10px;
    }
    p {
      color: var(--tertiory2);
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 25px;
      &.welcomemsg {
        font-size: 18px;
      }
    }
    input {
      padding: 10px 20px;
    }
    .flag-dropdown {
      background: var(--lytThemebackClr);
      border: 1px solid var(--borderClr);
      border-radius: 10px 0 0 10px;
      .selected-flag {
        background: var(--lytThemebackClr);
        border-radius: 8px 0 0 8px;
      }
      &.open {
        background: var(--lytThemebackClr);
        border-radius: 10px 0 0 10px;
        .selected-flag {
          background: var(--lytThemebackClr);
          border-radius: 10px 0 0 10px;
        }
      }
      .country-list {
        max-width: 256px;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    .icon {
      position: absolute;
      top: 35px;
      right: 15px;
      width: 20px;
      fill: #646464;
    }
    .btn {
      background: transparent;
      color: var(--whiteClr);
      border: 1px solid var(--whiteClr);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      width: 120px;
      padding: 0.6rem 1rem;
      border-radius: 50px;
      &:hover {
        background: var(--primaryClr) !important;
        color: var(--blackClr);
      }
    }
    .form-link {
      color: var(--tertiory1);
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
    }
    .sign-up {
      color: var(--tertiory1);
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      a {
        font-size: 16px;
        color: var(--tertiory1);
        text-decoration: underline;
      }
    }
  }
  &.login-modal {
    .form-steps {
      .form-control {
        @media (max-width: 767.98px) {
          border-radius: 0 !important;
        }
        &.username {
          @media (max-width: 767.98px) {
            border-bottom: 1px solid #454545 !important;
          }
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          @media (max-width: 767.98px) {
            box-shadow: 0 0 0 30px #181818 inset !important;
            border-color: #454545 !important;
          }
        }
      }
      .icon {
        @media (max-width: 767.98px) {
          top: 10px;
        }
      }
    }
  }
}

.offcanvas {
  &.user_section {
    max-width: 90%;
    .offcanvas-header {
      height: auto;
      background: #0088da !important;
      .btn-close {
        filter: invert(1);
      }
    }
    .offcanvas-body {
      background: #333333 !important;

      padding: 0;
      .upperbalnace {
        background: #333;
        padding: 15px;
      }
      .btn_primary {
        background: var(--primaryClr);
      }
      .menu-items {
        padding-left: 0;
        margin-top: 0;
        border-top: 1px solid var(--secondaryBdr);
        li {
          border-bottom: 1px solid #5a5858;
          a {
            padding: 1rem;
          }
        }
      }
      .btn_withdraw {
        background: transparent;
        color: var(--tertiory1);
        padding: 0.5rem 1rem;
        border-color: var(--tertiory1) !important;
        border-radius: 25px;
      }
      .btn_deposit {
        background: var(--primaryClr);
        color: var(--whiteText);
        width: 100%;
        border-color: var(--primaryClr) !important;
        border-radius: 25px;
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    border: 0;
    &.nav-tabs {
      @media screen and (max-width: 575.98px) {
        width: 100vw;
        margin-right: calc(var(--bs-gutter-x) * -0.5);
        margin-left: calc(var(--bs-gutter-x) * -0.5);
      }
    }
    li {
      width: 100%;
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: var(--whiteClr);
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 10px;
        border: 0;
        border-radius: 0;
        &.active {
          background: var(--primaryClr);
          color: var(--blackClr);
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      overflow: hidden;
      max-width: 400px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 2px 12px rgb(0 0 0 / 18%);
      .top-area {
        background: var(--secondary5);
        color: var(--tertiory1);
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        padding: 20px;
      }
      .bottom-area {
        background: var(--secondary6);
        padding: 20px;
        .details {
          margin-bottom: 0;
          h5 {
            color: var(--tertiory1);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          li {
            display: flex;
            align-items: center;
            div {
              color: var(--tertiory1);
              font-size: 14px;
              font-weight: 300;
              padding: 5px 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &:first-child {
                width: 130px;
              }
            }
          }
        }
      }
    }

    .upi-list {
      margin: 20px 0 40px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }

    form {
      padding: 30px;
      text-align: center;
      .info_text {
        color: var(--grayClr);
        text-align: left;
        padding-left: 1rem;
        margin-bottom: 0.5rem;
      }
      .text_actype {
        color: var(--textClr);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
      }
      input {
        padding: 14px 20px;
        &.form-check-input {
          background: var(--tertiory1);
          width: 24px;
          height: 24px;
          padding: 0;
          border: 2px solid var(--primaryClr);
          border-radius: 50px;
          position: relative;
          &:checked:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            background: var(--primaryClr);
            width: 16px;
            height: 16px;
            display: block;
            border-radius: 50px;
          }
        }
      }
      .form-check-inline {
        margin-right: 3rem;
        .form-check-input {
          cursor: pointer;
        }
        .form-check-label {
          color: var(--textClr);
          font-size: 15px;
          font-weight: 300;
          padding-left: 10px;
          cursor: pointer;
        }
      }
      .btn {
        background: var(--primaryClr);

        padding: 9px 30px !important;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        border: 0;
        border-radius: 25px;
      }
      .bank_saved_info {
        background: var(--secondary5);
        padding: 20px 20px 30px;
        border-radius: 10px;
        position: relative;
        .bank_name {
          position: relative;
          .form-check {
            .form-check-label {
              font-size: 14px;
              font-weight: 700;
            }
          }
          .acc_num {
            position: absolute;
            top: 25px;
            left: 35px;
          }
        }
        .dropdown {
          position: revert;
          .dropdown-toggle {
            background: transparent;
            min-width: auto;
            padding: 0;
            margin-top: 10px;
            border: 0;
            &:after {
              content: url("/assets/images/menu-dots.png");
              filter: brightness(4);
              border: 0;
            }
          }
          .dropdown-menu {
            transform: translate(0, 85px) !important;
            background: var(--secondary6);
            width: 100%;
            padding: 10px 20px;
            border: 0px;
            border-radius: 0px 0px 10px 10px;
            .dropdown-item {
              padding: 0 0.5rem;
              @media screen and (max-width: 575.98px) {
                padding: 0;
              }
              &:hover,
              &:focus {
                background: transparent;
                cursor: text;
              }
              .bank_details {
                li {
                  color: var(--tertiory2);
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5rem;
                  b {
                    min-width: 120px;
                    font-size: 0.85rem;
                    @media screen and (max-width: 575.98px) {
                      min-width: 110px;
                    }
                  }
                  span {
                    @media screen and (max-width: 575.98px) {
                      font-size: 0.85rem;
                      font-weight: 500;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .card-holder {
      height: 115px;
      background: var(--tertiory1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--lytThemebackClr3);
      width: 100%;
    }
  }
  .sports_sec {
    img {
      border-radius: 10px;
    }
    .dropdown {
      .dropdown-toggle {
        background: var(--headClr);
        color: var(--tertiory1);
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
        border-color: var(--primaryClr);
        border-radius: 0;
        position: relative;
        &::after {
          position: absolute;
          right: 20px;
          top: 50%;
        }
      }
      .dropdown-menu {
        background: var(--headClr) !important;
        width: 100% !important;
        padding: 0;
        margin-top: -2px;
        border: 1px solid var(--secondaryBdr);
        border-top: 0;
        border-radius: 0;
        .dropdown-item {
          color: var(--tertiory1);
          padding: 10px 10px;
          opacity: 0.7;
          &:not(:last-child) {
            border-bottom: 1px solid var(--secondaryBdr);
          }
          &:hover {
            background: var(--headClr) !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

.custom-tabs {
  .nav {
    flex-wrap: nowrap;
    background: #f2f2f2;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    li {
      margin: 0 10px;

      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 10px 0;
        color: var(--textClr);
        font-weight: 500;
        border: none;

        &.active {
          color: var(--primaryClr);
          background: none;
        }
      }
    }
  }

  .tab-pane {
    .title {
      background: #d8d8d8;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--whiteText);
      }
    }

    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          color: #838383;
        }
      }

      .status {
        background: #4caf50;
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }

      .listing {
        display: flex;
        flex-wrap: nowrap;

        li {
          margin: 0 2px;
          width: 100%;

          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #899d8a;
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      background: #d8d8d8;
      margin: 20px 0;
      padding: 0 1rem;

      .bet-btns {
        display: flex;

        li {
          background: #4caf50;
          color: var(--whiteText);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }

    .status-btns {
      display: flex;
      align-items: center;

      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteText);
        &.no {
          background: #796b4a;
        }
        &.yes {
          background: #2b3970;
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        margin: 0;
        li {
          display: flex;
          border-bottom: 1px solid var(--lytThemeClr2);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .time {
              color: #af2f2f;
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteText);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: #d8d8d8;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: #4caf50;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: #4caf50;
        font-size: 11px;
        color: var(--whiteText);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.footer {
  padding: 50px 0 0px;
  background: #353535 !important;
  .sectionDivide {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .singleSection {
      width: 45%;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
  }
  .footer-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
  }
  .providers_logo {
    padding-bottom: 30px !important;
    h4 {
      font-size: 20px;
      margin-bottom: 15px;
      color: var(--whiteClr);
    }
    @media (max-width: 1199.98px) {
      padding-bottom: 20px;
    }
    ul {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      li {
        margin-right: 20px;
        max-width: 18%;
        img {
          border-radius: 8px;
        }
        @media (max-width: 1199.98px) {
          max-width: 31%;
          margin: 1%;
        }
      }
    }
  }
  .footer-links {
    background: #004e8a;
    margin-top: 28px;
    width: 60% !important;
    @media (max-width: 991.98px) {
      margin-bottom: 30px;
    }
    ul {
      text-align: center;
      padding: 21px 0;

      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;

        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 5px;
          width: 1px;
          height: 14px;
          background: var(--whiteClr);
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: var(--whiteClr);
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
        }
      }
    }
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        padding: 8px;

        img {
          max-height: 46px;
        }
      }
    }
  }
  .provider-img {
    width: 120px;
  }
  .foot-discription {
    padding-top: 25px !important;
  }
  .social-icons {
    ul {
      display: flex;
      justify-content: center;
      li {
        margin: 0px 10px;
        svg,
        img {
          width: 28px;
        }
      }
    }
  }
  .footer-menu {
    background: #040404;
    height: 13.3333333333vw;
    border-top: 0.2666666667vw solid rgba(255, 255, 255, 0.2);
    z-index: 99999;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        width: 20%;
        height: auto;
        position: relative;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          height: 100%;
          img {
            width: 5.3333333333vw;
            height: 5.3333333333vw;
            margin: 0 auto 1.3333333333vw;
            &.active {
              display: none;
            }
          }
          span {
            color: var(--whiteClr);
            font-size: 3.2vw;
            font-weight: 400;
            line-height: 15px;
          }
          &.active {
            img {
              &.no_active {
                display: none;
              }
              &.active {
                display: block;
                filter: none !important;
              }
            }
          }
        }
        &.home {
          position: relative;
          a {
            position: absolute;
            bottom: 7px;
            left: -10px;
            width: 75px;
            height: 75px;
            img {
              width: 100%;
              height: 100%;
              max-height: 100%;
              filter: none !important;
            }
          }
        }
      }
    }
  }
  .paymentspartner {
    h5 {
      font-size: 20px;
      margin-bottom: 15px;
      color: var(--whiteClr);
      text-align: left;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 8px;
      li {
        img {
          width: 35px;
        }
      }
    }
  }
}
.profile-open {
  .my_menu {
    transform: translateY(0%);
    // display: block;
    height: 100%;
  }
}
.my_menu {
  position: fixed;
  // top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: var(--blackClr);
  overflow-y: auto;
  z-index: 99999;
  // display: none;
  transform: translateY(100%);
  transition: all 0.3s;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .headSec {
    background-image: url("./assets/images/mymenu-header-bg.png");
    width: 100%;
    height: 45.3333333333vw;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100%;
    padding-top: 35px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 26.6666666667vw;
      background: linear-gradient(
        to top,
        rgba(17, 17, 17, 0.6) 10%,
        rgba(17, 17, 17, 0) 100%
      );
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 13.3333333333vw;
      height: 13.3333333333vw;
      background: #000;
      color: var(--whiteClr);
      text-align: center;
      border-radius: 0 0 0 100%;
      z-index: 2;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 40%;
        left: 60%;
        width: 5.3333333333vw;
        height: 0.5333333333vw;
        margin: -0.2666666667vw 0 0 -2.6666666667vw;
        border-radius: 0.5333333333vw;
        background: var(--whiteClr);
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    .head-content {
      display: flex;
      align-items: center;
      padding: 0 2.6666666667vw;
      .profileImg {
        img {
          width: 17.3333333333vw;
          height: 17.3333333333vw;
          margin-right: 2.6666666667vw;
          border-radius: 17.3333333333vw;
        }
      }
      .profileInfo {
        h5 {
          color: var(--whiteClr);
          font-size: 4.2666666667vw;
        }
      }
    }
  }
  .bodySec {
    margin: -10.6666666667vw 0 0;
    padding: 0 2.6666666667vw;
    position: relative;
    .comn-box {
      background: #232323;
      margin: 0 0 2.6666666667vw;
      border-radius: 0.8vw;
      .headings {
        color: var(--whiteClr);
        font-size: 4vw;
        line-height: 9.3333333333vw;
        padding: 0 2.6666666667vw;
        margin-bottom: 0;
        border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 1.0666666667vw;
          height: 4.2666666667vw;
          margin-right: 1.3333333333vw;
          background: #0088da;
          vertical-align: middle;
        }
      }
      .flex-container {
        display: flex;
        padding: 1.3333333333vw 0;
        a {
          display: flex;
          flex: auto;
          flex-direction: column;
          align-items: center;
          width: 33.33%;
          padding: 1.8666666667vw 0;
          img {
            width: 9.3333333333vw;
            height: 9.3333333333vw;
            margin: 0 auto 1.3333333333vw;
            border-radius: 9.3333333333vw;
          }
          span {
            color: var(--whiteClr);
            font-size: 3.4666666667vw;
          }
        }
      }
    }
    .mainWallet {
      height: 18.4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.6666666667vw;
      p {
        color: #87cffc;
        font-size: 3.2vw;
        margin-bottom: 0;
      }
      h6 {
        color: #ffdf1a;
        font-size: 4.2666666667vw;
        margin-bottom: 0;
      }
    }
    .logout {
      padding: 2.6666666667vw;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 5.3333333333vw;
          height: 5.3333333333vw;
          margin: 0 1.3333333333vw 0 0;
          filter: brightness(100);
        }
        span {
          color: var(--whiteClr);
          font-size: 3.4666666667vw;
        }
      }
    }
  }
}
.light-theme {
  .footer-menu {
    border-color: #30343e;
    a {
      img {
        filter: brightness(1000);
      }
    }
  }
}
.middleContentSec .live_casino_page.live {
  margin-top: 0;
}
.live_casino_page {
  &.live {
    background: #1d1d27;
    color: #d8d8d8;
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);

      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}

.games-all {
  .casino_games {
    .shadow.rounded.position-relative {
      padding: 0px;
      margin: 10px 0px;
      width: 100%;
    }
  }
}

#Games-select {
  width: 100%;
  font-size: 20px;
  padding: 15px 10px;
  background: #0e1422;
  color: var(--whiteText);
  border-radius: 10px;
  border: 1px solid gold;
  margin-bottom: 20px;

  option {
    border-radius: 10px;
    font-size: 12px;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 0px;
  }
}

.contact_sec {
  .heading {
    color: var(--lytThemebackClr);
  }
  .contact_info {
    background: var(--whiteText4);
    border-radius: 10px 10px 0 10px;
    padding: 15px 40px;
    @media screen and (max-width: 576px) {
      padding: 15px 15px;
    }
    ul {
      li {
        display: flex;
        margin-bottom: 10px;
        a {
          display: flex;
          color: #fff;
        }
        .img_box {
          background: var(--primaryClr);
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          margin-right: 20px;
          border-radius: 0.35rem;
          img {
            width: 30px;
            height: auto;
          }
        }
        h5 {
          // color: var(--lytThemebackClr);
          color: #000;
        }
        p {
          // color: var(--lytThemebackClr);
          color: #000;

          font-size: 15px;
          max-width: 200px;
        }
      }
    }
  }
  form {
    .form-floating {
      background: var(--lytThemebackClr);
      margin-bottom: 1.5rem;
      border-radius: 10px;
      .form-control {
        color: var(--whiteText);
      }
      label {
        color: #6c757d;
      }
    }
  }
  .contact_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateX(20px);
          transform: translateX(20px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}

.contact-area {
  .panel {
    background: var(--whiteText4);
    padding: 10px 20px;
    border-radius: 5px;
    svg {
      font-size: 30px;
    }
  }
}

.non_cash_balance {
  padding: 10px 10px;
  background: #0e1627;
  margin: 10px 0 20px;
  border-radius: 5px;
  .non_cash_balance_heading {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .non_cash_balance small {
      font-size: 12px;
      margin: 0px 10px;
      font-weight: 400;
      color: #959595;
    }
  }
}

.modal {
  &.changePasswordModal.show {
    .modal-dialog {
      .modal-content {
        .modal-header {
          background: var(--whiteClr);
          border: 0;
        }
        .modal-body {
          background: var(--headClr);
          .icon {
            position: absolute;
            right: 10px;
            top: 10px !important;
            img {
              width: 20px;
            }
          }
        }
        .modal-footer {
          background: var(--headClr);
          border: 0;
        }
      }
    }
  }
}
.footer .footer-links ul {
  display: flex;
  align-items: center;
}
.changes_password {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  color: var(--whiteText);
  background: transparent;
}

.fade.changePasswordModal.modal.show .modal-dialog .modal-content {
  background: #18243d;
}

.fade.changePasswordModal.modal.show .btn.btn-primary {
  background: goldenrod;
  border: 0px;
}

.Support_page {
  .footer {
    .footer-links.mb-3 {
      display: none;
    }
  }
}

// Media Quary Start ======>>
@media only screen and (max-width: 1199.98px) {
  .main .mainBanner .slick-slide .content h2 {
    font-size: 2rem;
    max-width: 250px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 1rem;
    max-width: 300px;
  }
}

@media only screen and (max-width: 767.98px) {
  .valuesDeposit {
    align-items: center;
    h3 {
      font-size: 16px;
    }
    .copyValue {
      font-size: 15px !important;
    }
  }
  .allcasino {
    align-items: center;
  }
  .providerPage a {
    width: 47.3% !important;
  }
  .top4_banners2 {
    display: flex !important;
    div {
      img {
        object-fit: cover !important;
        height: auto !important;
      }
    }
  }
  .Bottom_head ul {
    justify-content: center;
    li {
      display: none !important;
      &:first-child,
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(9) {
        display: block !important;
      }
    }
  }
  .banner5 {
    img {
      height: 140px !important;
    }
  }
  .top4_banners div img {
    height: 140px !important;
  }
  .top4_banners {
    display: grid !important;
  }
  .landingPage_games .gamesss {
    width: 48.9% !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area ul {
    margin: 0 !important;
    width: auto;
  }
  .cards {
    width: 100% !important;
  }
  .primary_card .stats-card {
    display: grid !important;
    ul {
      width: 100% !important;
      border-right: 0 !important;
    }
  }
  .account-statement button {
    .calendar_icon {
      svg {
        display: block;
      }
    }
  }
  .Spribe_Games .Provider_Games a {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .withdraw_btn a.btn_primary.btn-primary {
    margin-left: 0;
    margin-top: 12px;
  }
  .listings {
    .left-arrows {
      display: none;
    }
  }
  .Live_casino_tabs {
    .live_heading {
      img {
        width: 20% !important;
      }
    }
    .live_heading.ev-heading {
      img {
        width: 30% !important;
      }
    }
  }
  .main .mainBanner .slick-slide .content {
    width: 80%;
  }
  .main .mainBanner .slick-slide .content h2 {
    font-size: 1.5rem;
  }
  .main .mainBanner .slick-slide .content p {
    font-size: 0.85rem;
  }
  .footer {
    padding: 25px 0 0px;
  }
  // .Live_games_row {
  //   justify-content: center;
  //   align-items: center;

  //   .Live_games_col {
  //     width: auto;
  //   }
  // }
}

@media only screen and (max-width: 575.98px) {
  .main .Live_casino_tabs .nav-tabs {
    background: #0e1422;
    padding-bottom: 0px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  #basic-navbar-nav .me-auto.nav .nav-link {
    display: none;
  }

  .live_heading {
    padding: 10px 0px;
    margin: 5px 0px;
    font-size: 26px;
  }

  .main .Live_casino_tabs .nav-tabs .nav-item .nav-link {
    background-position: 32px 10px;
    background-size: 30px 30px;
    padding: 45px 5px 5px 5px;
    width: 95px;
  }

  .live_heading img {
    width: 110px;
  }

  .Spribe_Games .shadow.rounded.position-relative {
    padding: 0px 10px;
  }

  .shadow.rounded.position-relative {
    width: 160px !important;
    padding: 0px 0px;
  }
  vailable .Payments_Games .shadow.rounded.position-relative {
    width: 85px !important;
  }

  .Provider_Games .shadow.rounded.position-relative {
    width: 120px !important;
    padding: 0 !important;
  }

  .Provider_Games .shadow.rounded.position-relative img {
    width: 100%;
    height: auto;
  }

  .listings.pt-5 {
    padding-top: 15px !important;
  }

  .footer .footer-links ul li {
    margin: 5px;

    padding: 0 3px;
  }
  .games-all .shadow.rounded.position-relative {
    min-width: calc(100% - 0px);
    width: calc(100% - 0px) !important;
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .listings .filter .btn-outline-primary {
    width: 100%;
  }

  .tabs-area {
    .tab-content {
      form {
        padding: 20px 40px 45px;
        text-align: center;
      }
    }
  }

  .main {
    .sectionTitle {
      margin-top: 0px !important;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .main {
    .profile-img {
      padding-top: 30px;
    }
  }

  .choose_file h4 {
    left: 35%;
  }

  .mstrpassword {
    max-width: 28ch;
  }

  #Games-select {
    option {
      border-radius: 10px;
      font-size: 12px;
    }
  }

  .main .profile-img img {
    max-width: 100px;
    width: 100px;
    height: 100px;
  }

  .main .profile-img {
    padding-top: 0px;
  }

  .account_details {
    text-align: center;
  }

  .Payments_Games .shadow.rounded.position-relative img {
    width: 200px;
    height: auto;
  }
}
.main {
  .Live_casino_tabs {
    .nav-tabs {
      .nav-item {
        .nav-link.active {
          background-image: url("../public/images/Roulette_Icons.png");
          border: 0 !important;
        }
        .nav-link {
          background-image: url("../public/images/games-image/Roulette_Icons.png");
          &:hover {
            border: 0 !important;
          }
        }
        #uncontrolled-tab-example-tab-Baccarat.active {
          background-image: url("../public/images/Baccarat_Icons.png");
        }
        #uncontrolled-tab-example-tab-Baccarat {
          background-image: url("../public/images/games-image/Baccarat_Icons.png");
        }
        #uncontrolled-tab-example-tab-Andar\ Bahar.active {
          background-image: url("../public/images/Andar_bahar_Icons.png");
        }
        #uncontrolled-tab-example-tab-Andar\ Bahar {
          background-image: url("../public/images/games-image/Andar_bahar_Icons.png");
        }
        #uncontrolled-tab-example-tab-Poker.active {
          background-image: url("../public/images/Poker_Icons.png");
        }
        #uncontrolled-tab-example-tab-Poker {
          background-image: url("../public/images/games-image/Poker_Icons.png");
        }
        #uncontrolled-tab-example-tab-Blackjack.active {
          background-image: url("../public/images/Blackjack_icons.png");
        }
        #uncontrolled-tab-example-tab-Blackjack {
          background-image: url("../public/images/games-image/Blackjack_icons.png");
        }
        #uncontrolled-tab-example-tab-TeenPatti.active {
          background-image: url("../public/images/Teenpatti.png");
        }
        #uncontrolled-tab-example-tab-TeenPatti {
          background-image: url("../public/images/games-image/Teenpatti.png");
        }
        #uncontrolled-tab-example-tab-32Card.active {
          background-image: url("../public/images/casino.png");
        }
        #uncontrolled-tab-example-tab-32Card {
          background-image: url("../public/images/games-image/casino.png");
        }
        #uncontrolled-tab-example-tab-Seven.active {
          background-image: url("../public/images/seven.png");
        }
        #uncontrolled-tab-example-tab-Seven {
          background-image: url("../public/images/games-image/seven.png");
        }
        #uncontrolled-tab-example-tab-Dices.active {
          background-image: url("../public/images/dices.png");
        }
        #uncontrolled-tab-example-tab-Dices {
          background-image: url("../public/images/games-image/dices.png");
        }
        #uncontrolled-tab-example-tab-BetNumber.active {
          background-image: url("../public/images/bet_on_numbers.png");
        }
        #uncontrolled-tab-example-tab-BetNumber {
          background-image: url("../public/images/games-image/bet_on_numbers.png");
        }
      }
    }
  }
}

.live_heading-center {
  text-align: center;
  color: #ffc400;
}

.Live_casino_tabs {
  .live_heading {
    img {
      width: 10%;
    }
  }
  .live_heading.ev-heading {
    img {
      width: 13%;
    }
  }
  .hot_games {
    ul {
      li {
        flex: 1;
      }
    }
  }
}

.profit_loss_heading {
  background: var(--tableHeadBG) !important;
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--primaryClr);
}

.table {
  background: var(--tableBG);
  margin: 0;
  thead {
    background-color: #3e3f40;
    tr {
      border-color: var(--tableBrder);
      border-top-color: var(--tableBorderTop) !important;
      th {
        color: var(--whiteClr);
        border: 0;
      }
    }
  }
  tbody {
    color: var(--whiteClr);
    tr,
    &:nth-of-type(odd) {
      border-bottom: 1px solid #3e3e3e;
      background: #313131;
      td {
        background: #313131 !important;
        color: var(--whiteClr);
        border: 0;
        .edit_password_button {
          .btn {
            background: transparent;
            padding: 0;
            border: 0;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  .all-providers-head {
    display: flex;
  }
  .provider-main {
    margin-top: 10px;
  }
  .providers-img {
    width: 30%;
    margin: 0 10px;
  }
  .providers-img img {
    width: 100%;
    height: 100%;
  }

  #basic-navbar-nav {
    .provider-img {
      width: 60px;
    }
  }
}
.Qr-code-scanner {
  display: flex;
  justify-content: center;
  img {
    max-width: 250px;
    height: auto;
    @media screen and (max-width: 575.98px) {
      max-width: 180px;
    }
  }
}

.Toastify .Toastify__toast-container {
  z-index: 999999999999 !important;
}

.country-name {
  color: var(--tertiory8);
}
.country-list {
  li {
    display: table;
  }
}
.fact_sec {
  padding: 20px 0;
  a {
    background: var(--secondary4) 0% 0% no-repeat padding-box;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    max-width: 210px;
    height: 80px;
    display: block;
    padding: 0 10px 0 0;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 5px 5px 6px rgb(0 0 0 / 5%);
    .tabs-inner-box {
      display: flex;
      align-items: flex-end;
      height: 100%;
      .mob-all-icon {
        img {
          width: 70px;
        }
      }
      .web-game-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .option-name {
          color: var(--tertiory2);
          font-size: 15px;
          line-height: 16px;
          margin-bottom: 4px;
        }
        .play-btn {
          color: var(--primaryClr);
          font-size: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 28px !important;
    height: 29px !important;
    background: #c9a145 !important;
    border-radius: 50% !important;
    &:before {
      line-height: 5px !important;
      font-size: 26px !important;
    }
  }
  .slick-prev {
    left: -22px !important;
    &:before {
      content: "\00AB" !important;
    }
  }
  .slick-next {
    right: -22px !important;
    &:before {
      content: "\00BB" !important;
    }
  }

  .fact-sec-mobile {
    ul {
      display: grid !important;
      gap: 15px;
      grid-template-columns: repeat(4, 1fr);
      li {
        a {
          background: transparent !important;
          font-size: 12px;
          font-weight: 600;
          height: auto;
          padding: 0;
          box-shadow: none;
          .mob-all-icon {
            background-color: var(--lytThemeClr2);
            display: block;
            border-radius: 12px;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .option-name {
            color: var(--tertiory2);
            font-size: 11px;
            font-weight: 700;
            line-height: 13px;
            margin-bottom: 0;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
.game_types {
  .desktop-live-deal {
    padding-top: 30px;
    .section-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767.98px) {
        flex-wrap: wrap;
      }
      h3 {
        color: var(--headClr);
        font-size: 18px;
        margin-bottom: 0;
        position: relative;
        @media (max-width: 767.98px) {
          margin-bottom: 20px;
        }
        &:after {
          content: "";
          position: absolute;
          height: 2px;
          width: 57px;
          background-color: var(--primaryClr);
          bottom: -4px;
          left: 0;
          display: none;
        }
        a {
          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }
    .mob-images-section {
      margin-top: 25px;
      @media (max-width: 767.98px) {
        margin-top: 0px;
      }
      .mob-images-head {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(4, 1fr);
        @media (max-width: 767.98px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .mob-images {
          border-radius: 8px;
          box-shadow: 3px 5px 8px rgb(0 0 0 / 28%);
          img {
            width: 100% !important;
            border-radius: 8px;
          }
          &.showall_btn {
            grid-column: 1 / 3;
            position: relative;
            img {
              border-radius: 8px 8px 0 0;
            }
            a {
              background: var(--secondary5) !important;
              margin: 0;
              font-weight: 500;
              border-radius: 0 0 8px 8px;
              width: 100%;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.not-registered {
  margin-top: 12px;
  p {
    margin-bottom: 0 !important;
    line-height: inherit !important;
    font-size: 13px !important;
  }
  ul {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    li {
      margin-right: 10px;
    }
  }
}
.game-page {
  .provider-game-section {
    margin-bottom: 25px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 19%;
        margin: 0.5%;
        @media only screen and (max-width: 767.98px) {
          width: 32% !important;
        }
        @media only screen and (max-width: 575.98px) {
          width: 49% !important;
        }
      }
    }
  }
}
.providers-img {
  margin: 10px !important;
}
.filter-added {
  display: flex;
  .dropdown {
    width: 32%;
    height: 45px;
    margin: 0.5%;
    button {
      background: #1e1e1e;
      border: 1px solid var(--primaryClr);
      border-radius: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background: #1e1e1e;
        border: 1px solid var(--primaryClr);
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      background: #1e1e1e;
      padding: 0;
      a {
        color: #b8b8b8;
        border-bottom: 1px solid #3a3a3a;
        padding: 6px 12px;
        &:hover {
          background-color: transparent;
          color: #b8b8b8;
        }
      }
    }
  }
  .favrouite {
    width: 45px;
    height: 45px;
    background: #1e1e1e;
    border: 1px solid #c9a145;
    margin: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 26px;
      fill: var(--primaryClr);
    }
  }
}

.support-header {
  text-align: center;
  .favicon {
    padding: 30px;
    background-color: #004e8a;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .support-heading {
    padding: 18px 0;
    h4 {
      color: var(--headClr);
      font-size: 24px;
      font-weight: 600;
    }
    p {
      color: #3d4244;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .chat-call-support {
    display: flex;
    justify-content: center;
    margin-top: 0;
    .chat-box {
      width: 25%;
      text-align: left;
      padding: 0 25px;
      &:nth-child(2) {
        border-right: 1px solid #a5a5a5;
        border-left: 1px solid #a5a5a5;
      }
      h2 {
        color: var(--headClr);
        font-size: 16px;
      }
      p {
        color: #3d4244;
        font-size: 28px !important;
        font-weight: 500;
      }
      ul {
        display: flex;
        justify-content: center;
        li {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 35px;
          }
        }
      }
      .email-section {
        margin-bottom: 20px;
        margin-top: 15px;
        p {
          font-size: 14px;
          color: var(--headClr);
          margin: 0;
        }
        a {
          color: #3d4244;
          text-decoration: underline;
          font-size: 25px;
        }
      }
    }
  }
}

.static_pages {
  h4 {
    color: var(--whiteClr);
    margin-top: 25px;
  }
  h6 {
    color: var(--whiteClr);
    font-weight: 600;
    margin-top: 15px;
  }
  p {
    color: var(--whiteClr);
  }
}
.privacy_policy.main {
  min-height: inherit !important;
  h4,
  h6 {
    color: var(--headClr);
  }
  p {
    color: #3d4244;
  }
}

.afterlogin {
  .main {
    .leftbarSec {
      @media (max-width: 991.98px) {
        height: calc(100vh - 26.6666666667vw) !important;
      }
      @media only screen and (min-device-width: 280px) and (max-device-width: 812px) {
        height: calc(100vh - 26.6666666667vw) !important;
        height: calc(var(--vh, 1vh) * 100 - 26.6666666667vw) !important;
      }
    }
  }
}
.header-bg,
.live_casino_page {
  .afterlogin {
    .main {
      .leftbarSec {
        @media (max-width: 991.98px) {
          height: calc(100vh - 13.3333333333vw) !important;
        }
        @media only screen and (min-device-width: 280px) and (max-device-width: 991.98px) {
          height: calc(100vh - 13.3333333333vw) !important;
          height: calc(var(--vh, 1vh) * 100 - 13.3333333333vw) !important;
        }
      }
    }
  }
}
.leftbarSec {
  background: (--whiteClr);
  width: 200px;
  height: calc(100vh - 130px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (max-width: 991.98px) {
    // position: fixed;
    // transform: translateX(-100%);
    background: #252525;
    transition: all 0.3s;
    z-index: 999;
    width: 50.6666666667vw;
    position: relative;
    top: 0;
    height: calc(100vh - 26.6666666667vw);
    box-shadow: 0.5333333333vw 0 0.8vw #0000001a;
  }
  @media only screen and (min-device-width: 280px) and (max-device-width: 812px) {
    height: calc(100vh - 26.6666666667vw) !important;
    height: calc(var(--vh, 1vh) * 100 - 26.6666666667vw) !important;
  }
  .games_link {
    position: relative;
    &:not(:last-child):after {
      content: "";
      display: block;
      width: 100%;
      height: 2.6666666667vw;
      margin-top: -0.2666666667vw;
      border-top: 0.2666666667vw solid #464646;
      background: #111111;
    }
    .sport-event-count {
      display: none;
    }
    h6 {
      color: var(--headClr);
      font-size: 13px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 5px 10px 10px;
      border-bottom: 2px solid #eef2f5;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 105px;
        height: 1px;
        top: 14px;
        right: 0;
        background-color: var(--headClr);
      }
    }
    ul {
      li {
        cursor: pointer;
        border-bottom: 2px solid #eef2f5;
        @media (max-width: 991.98px) {
          border-bottom: 0.2666666667vw solid #464646 !important;
        }
        > div,
        a {
          line-height: 35px;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            top: 0;
            left: -10px;
            width: 0.8vw;
            height: 100%;
            background: #0088da;
            opacity: 0;
            transition: all 0.3s;
          }
          &:hover,
          &:focus {
            background: #333;
            &:before {
              opacity: 1;
              left: 0;
            }
          }
          img {
            width: 8vw;
            height: 8vw;
            margin-right: 2.6666666667vw;
          }
          span {
            color: var(--headClr);
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            opacity: 0.8;
            @media (max-width: 991.98px) {
              color: var(--whiteClr);
              font-size: 3.4666666667vw;
              font-weight: 400;
              opacity: 1;
            }
          }
          .sport-event-count {
            background: var(--primaryClr);
            color: var(--blackClr);
            font-size: 12px;
            margin-left: auto;
            padding: 0.25em 0.4em;
            border-radius: 2px;
          }
        }
      }
      &.contact {
        li {
          margin: 2.6666666667vw auto;
          border-bottom: 0 !important;
          a {
            background: #333333;
            width: 45.3333333333vw;
            height: auto;
            padding: 2.6666666667vw;
            margin: 0 auto;
            border: 0.2666666667vw solid #464646;
            border-radius: 0.8vw;
          }
        }
      }
    }
  }
}
.sub-leftbar-wrapper {
  .sub-leftbar {
    position: absolute;
    top: 0;
    left: 200px;
    width: 29.3333333333vw;
    height: 100%;
    background: #333;
    transform: translateX(-110%);
    transition: all 0.5s ease-in-out;
    box-shadow: 0.8vw 0 0.8vw #0000004d;
    @media (max-width: 991.98px) {
      top: 13.3333333333vw;
      left: 50.6666666667vw;
      height: calc(100vh - 26.6666666667vw);
    }
    @media only screen and (min-device-width: 280px) and (max-device-width: 812px) {
      height: calc(100vh - 26.6666666667vw) !important;
      height: calc(var(--vh, 1vh) * 100 - 26.6666666667vw) !important;
    }
    &.active {
      transform: translateX(0);
    }
    ul {
      overflow-y: auto;
      @media (max-width: 991.98px) {
        height: calc(100vh - 26.6666666667vw);
      }
      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }
      li {
        width: 90%;
        height: auto;
        margin: 0 auto;
        padding: 5.3333333333vw 0;
        border-bottom: 0.2666666667vw solid #464646;
        &:last-child {
          border: 0;
        }
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 10.6666666667vw;
            height: 10.6666666667vw;
            margin: 0 auto 2.1333333333vw;
          }
          span {
            color: var(--whiteClr);
            font-size: 3.4666666667vw;
            text-align: center;
          }
        }
      }
    }
  }
}
// sportsbook css
.main {
  .middleContentSec {
    width: 100%;
    .sports_page_banner {
      position: relative;
    }
    .news {
      background: rgba(0, 104, 167, 0.9);
      width: 100%;
      height: 30px;
      line-height: 30px;
      @media (min-width: 992px) {
        position: absolute;
        bottom: 0;
      }
      @media (max-width: 991.98px) {
        background: var(--blackClr);
        border-bottom: 0.2666666667vw solid black;
      }
      .news-img {
        @media (max-width: 991.98px) {
          width: 50px;
          text-align: center;
        }
        img {
          width: 18px;
          height: auto;
          @media (max-width: 991.98px) {
            width: 20px;
          }
        }
      }
      marquee {
        color: var(--whiteClr);
        margin-left: 12px;
      }
    }
    .tabs_sec {
      background: var(--blackClr);
      .nav-pills {
        background: var(--blackClr);
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        // position: sticky;
        // top: 70px;
        z-index: 997;
        border: 0;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        .nav-item {
          width: auto;
          min-width: 18.1818181818%;
          &.min-wd-22 {
            min-width: 22.1818181818%;
          }
          .nav-link {
            color: var(--whiteClr);
            font-size: 3.4666666667vw;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3vw 0;
            transition: all 0.3s;
            min-width: max-content;
            border-radius: 0;
            position: relative;
            &:after {
              content: "";
              display: none;
              position: absolute;
              z-index: 2;
              bottom: 0;
              left: 50%;
              transform: translate(-50%);
              border-width: 2.1333333333vw;
              border-style: solid;
              border-color: transparent transparent #111111;
            }
            &.active {
              background: #2b2b2b;
              &:after {
                display: block;
              }
            }
            img {
              width: 10.6666666667vw;
              height: 10.6666666667vw;
              margin: 0 auto 1.8666666667vw;
              transition: all 0.3s;
            }
          }
        }
      }
      .tab-content {
        padding: 0 2.6666666667vw 2.6666666667vw;
        .tab-pane {
          border-radius: 0.8vw;
          h3 {
            color: var(--whiteClr);
            font-size: 4vw;
            font-weight: 700;
            padding: 2.6666666667vw 0;
            margin-bottom: 0;
            position: relative;
            &:before {
              content: "";
              display: inline-block;
              width: 1.0666666667vw;
              height: 4vw;
              margin-right: 1.3333333333vw;
              background: #0088da;
              vertical-align: middle;
            }
          }
          ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 0.5333333333vw;
            width: 100%;
            height: auto;
            li {
              width: 100%;
              background: #222222;
              a {
                color: var(--whiteClr);
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2.6666666667vw 0;
                img {
                  width: 10.6666666667vw;
                  height: 10.6666666667vw;
                }
                span {
                  font-size: 3.4666666667vw;
                  margin-top: 1.0666666667vw;
                }
              }
            }
          }
        }
      }
    }
    .favourites_sec,
    .featured_sec {
      @media (max-width: 991.98px) {
        padding: 2.6666666667vw 0 2.6666666667vw 4vw;
      }
      h3 {
        color: var(--whiteClr);
        font-size: 4vw;
        font-weight: 700;
        margin-bottom: 2.6666666667vw;
        position: relative;
        &:before {
          content: "";
          display: inline-block;
          width: 1.0666666667vw;
          height: 4vw;
          margin-right: 1.3333333333vw;
          background: #0088da;
          vertical-align: middle;
        }
      }
      ul {
        display: flex;
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        li {
          margin: 6px 12px 6px 0;
          @media (max-width: 991.98px) {
            margin: 1.3333333333vw 2.6666666667vw 1.3333333333vw 0;
          }
          p {
            background: #0088da;
            color: var(--whiteClr);
            font-size: 15px;
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding-left: 18px;
            margin-bottom: 0;
            border-radius: 3px 3px 0 0;
            @media (max-width: 991.98px) {
              display: none;
            }
          }
          img {
            border-radius: 0 0 3px 3px;
            @media (max-width: 991.98px) {
              min-width: 72vw;
              border-radius: 1.3333333333vw;
              box-shadow: 0 0 0.8vw #000c;
            }
          }
        }
      }
    }
    .featured_sec {
      ul {
        li {
          @media (max-width: 991.98px) {
            margin: 0 2.6666666667vw 0 0;
          }
          img {
            @media (max-width: 991.98px) {
              min-width: 46vw;
              border-radius: 1.3333333333vw 1.3333333333vw 0 0 !important;
              box-shadow: none !important;
            }
          }
          p {
            @media (max-width: 991.98px) {
              background: #222222;
              font-size: 4vw;
              display: block !important;
              height: auto;
              line-height: normal;
              padding: 2.1333333333vw;
              border-radius: 0 0 1.3333333333vw 1.3333333333vw;
            }
          }
        }
      }
    }
    .side_contact {
      position: fixed;
      top: 150px;
      right: -220px;
      align-items: flex-start;
      transition: all 0.3s;
      &:hover {
        right: 0;
      }
      .slide-btn {
        background: #0075bb;
        padding: 10px 5px;
        cursor: pointer;
        border: 0;
        border-radius: 5px 0 0 5px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
        img {
          width: 25px;
        }
      }
      .contact-box {
        background: #3e3e3e;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
        width: 220px;
        .contact-logo {
          text-align: center;
          margin: 10px 0;
          img {
            width: 61px;
          }
        }
        .site-logo {
          text-align: center;
          margin: 10px 0;
          img {
            max-height: 19px;
          }
        }
        .contact-content {
          background: #f5f5f5;
          color: #333;
          width: 90%;
          margin: 0 auto 10px;
          overflow: hidden;
          border-radius: 5px;
          li {
            padding: 0 10px;
            a {
              display: block;
              border-bottom: 1px solid #ccc;
              img {
                width: 24px;
                margin-right: 5px;
              }
              span {
                color: #333;
                font-size: 14px;
                display: inline-block;
                padding: 8px 0;
              }
            }
          }
        }
      }
    }
  }
  .rightbarSec {
    background: var(--whiteClr);
    width: 400px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--headClr);
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--headClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #fff;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                color: #fff;
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 120px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: var(--headClr);
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #0c1d2c;
                    display: block;
                    tr {
                      th {
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: #000;
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                color: var(--whiteClr);
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondary2);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
    position: relative;
    a.openbets {
      border: 1px solid var(--whiteClr);
      color: var(--whiteClr);
      padding: 8px 18px;
      border-radius: 25px;
      position: absolute;
      right: 10px;
      top: 50%;
      text-transform: uppercase;
      transform: translateY(-50%);
    }
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--whiteClr);
      border: 1px solid #dddddd;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid #dddddd;
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--blackClr);
            cursor: pointer;
            .date_time_info {
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: #4e944c;
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin-right: 5px;
            width: 43px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondary2);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      // padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: var(--whiteClr);
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: var(--primaryClr);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        margin-right: 5px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    .back-link {
      margin-top: 0;
    }
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--headClr);
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: #132b41;
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid #132b41;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondary2);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.sports_page_banner img {
  width: 100%;
  // max-height: 500px;
  // object-fit: cover;
}
.sports_page_banner .slick-slider .slick-dots {
  bottom: 8px;
}
.sports_page_banner .slick-slider .slick-dots li {
  height: 3px;
}
.sports_page_banner .slick-slider .slick-dots li button {
  height: 3px;
  padding: 0;
}
.sports_page_banner .slick-slider .slick-dots li button:before {
  background: hsla(0, 0%, 100%, 0.7);
  color: transparent;
  height: 3px;
  opacity: 1;
  width: 25px;
}
.sports_page_banner .slick-slider .slick-dots li.slick-active button:before {
  background-color: var(--primaryClr);
}
.betslip_popup {
  .modal-body {
    background: var(--headClr);
    padding: 0;
    .stack-win-value {
      flex-wrap: wrap;
      div {
        width: 46%;
      }
    }
    .auto-stake {
      ul {
        li {
          .stack-btn {
            background: var(--secondary2);
            color: var(--whiteClr);
          }
        }
      }
    }
  }
}
.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid var(--secondary2);
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      color: var(--whiteClr);
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondary2);
      }
    }
  }
  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondary2);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #0cd664;
        color: #000;
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }
    .cancel-btn {
      background: #af2f2f;
      color: #000;
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.live-deal-section {
  ul {
    display: flex;
    gap: 10px;
    li {
      width: 25%;
    }
  }
}

.deposit_img {
  img {
    animation: moving 3s infinite linear;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.transfer_details {
  .payment-transfer-deposit {
    background: #313131;
    border-radius: 8px;
    color: var(--tertiory5);
    h5 {
      margin: 0;
      padding: 10px 0;
      background: #3a3a3a;
      border-radius: 8px 8px 0 0;
      color: var(--tertiory5);
      font-size: 1.5rem;
    }
    .bankdetailsdeposit {
      padding: 15px 20px;
      p {
        color: var(--tertiory5);
        font-size: 14px;
      }
    }
  }
}

.steps-canvas .form-steps input {
  margin-bottom: 15px;
  border-radius: 10px !important;
}

.gatewaySection {
  max-width: 800px;
  margin: 25px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: linear-gradient(0deg, #90c5ee 0%, #1f79be 100%);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;
        &.active {
          background: var(--primaryClr);
          border-radius: 15px 15px 0px 0px;
          color: #000;
        }
      }
    }
  }
  .tab-content {
    background: var(--whiteClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(0deg, #eef2f5 0%, #ffffff 100%);
        border: 0.5px solid #d6d6d6;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #d6d6d6;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-weight: 500;
          font-size: 12px;

          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  margin: 25px 0px 25px;

  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--headClr);
  margin-top: 15px;
  color: var(--whiteClr);
  padding: 8px;

  h4 {
    margin: 0;
    color: var(--whiteClr);
  }
}
.new_table {
  thead {
    th {
      padding: 5px;
    }
  }
  tbody {
    background: #2b2d30;
    tr td {
      border: 0;
    }
  }

  padding: 6px 0;
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.cards {
  span {
    color: orange;
    margin-left: 4px;
  }
  padding: 15px;
  border-radius: 10px;
  width: 32.33%;
  margin: 0.5%;
  background: #1e1e1e;
  box-shadow: 0px 1px 10px 3px #3a3a3a70;
}
.amount_data {
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .status_claimed {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}
.cancel_bonus {
  background: red;
  padding: 2px 4px;
  font-size: smaller;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
}
.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}
.eye_ {
  fill: var(--primaryClr);
}
.modal-backdrop.show {
  opacity: 0.1 !important;
}
.referral_card_main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px !important;
  gap: 15px;
  @media (max-width: 575.98px) {
    gap: 10px;
  }
  .referrals_cards {
    background: #313131;
    width: calc(33.33% - 10px);
    padding: 1.5rem;
    border-radius: 5px;
    @media (max-width: 991.98px) {
      padding: 0.75rem 1rem;
    }
    &:not(:last-child) {
      @media (max-width: 575.98px) {
        width: calc(50% - 5px);
      }
    }
    &:last-child {
      @media (max-width: 575.98px) {
        width: calc(100% - 0px);
      }
    }
    h5 {
      font-size: 1rem;
      color: var(--whiteClr);
    }
    .referr_user {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--whiteClr);
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referral_link {
  background: #313131;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  @media (max-width: 991.98px) {
    padding: 6px 10px;
  }
  h5 {
    color: var(--whiteClr);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
  }
  div {
    background: #3e3e3e;
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    padding: 8px 15px;
  }
  .btn-primary {
    background: linear-gradient(
      to right,
      #facd04 0,
      #fbc103 15%,
      #fbc103 30%,
      #facd04 55%,
      #facd04 100%
    );
    border-color: #fbc103 !important;
  }
}
.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;
  button {
    background: #313131 !important;
    border: 0 !important;
    color: var(--whiteClr) !important;
    padding: 8px 35px;
  }
}
.referr_table {
  color: var(--whiteClr);
  thead {
    background: #3e3f40;
    border: 0;
    th {
      border: 0;
    }
  }
  tbody {
    background-color: #000;
    td {
      border-color: #333;
    }
  }
}

.sport-buttons ul {
  padding: 10px 10px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  li {
    border-radius: 3px;
    height: 36px;
    background-color: initial;
    a {
      position: relative;
      color: var(--whiteClr);
      display: block;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .sports-content-text {
        position: absolute;
        top: 0;
        left: 5px;
        display: flex;
        flex-direction: column;
        img {
          width: 16px;
          height: 16px;
        }
        span {
          text-transform: none;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
.promotion-buttons ul {
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 45%;
  grid-column-gap: 1vw;
  grid-auto-flow: column;
  padding-bottom: 4px;
  grid-row-gap: 7px;
  width: auto;
  overflow-x: auto;
  margin: 12px 10px 0;
  display: grid;
  justify-content: space-between;
  li {
    flex: 1 1;
    height: 36px !important;
    margin: 0 !important;
    width: 100%;
    font-family: "Lato", sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;
    a.promotion-banner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 16px;
      background-color: initial;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: auto 100%;
      color: var(--whiteClr);
      border-radius: 3px;
      &.promotionBanner1 {
        background-image: url(./assets/images/banner/home/aviator.png);
        background-size: 100% auto;
      }
      &.promotionBanner2 {
        background-image: url(./assets/images/banner/home/evolution-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner3 {
        background-image: url(./assets/images/banner/home/sportsbook-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner4 {
        background-image: url(./assets/images/banner/home/livecasino-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner5 {
        background-image: url(./assets/images/banner/home/e-cricket-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner6 {
        background-image: url(./assets/images/banner/home/cardgames-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner7 {
        background-image: url(./assets/images/banner/home/slot-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner8 {
        background-image: url(./assets/images/banner/home/multiplayer-quick-bg.png);
        background-size: 100% auto;
      }
      &.promotionBanner9 {
        background-image: url(./assets/images/banner/home/fishinggames-quick-bg.png);
        background-size: 100% auto;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
    }
  }
}

.indian-card-games {
  .game-heading {
    min-height: 48px;
    display: flex;
    padding: 5px 9px;
    margin-top: 30px;
    background-color: var(--headClr);
    color: var(--whiteClr);
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    position: relative;
    a.openbets {
      border: 1px solid var(--whiteClr);
      color: var(--whiteClr);
      padding: 5px 15px;
      border-radius: 25px;
      position: absolute;
      right: 7px;
      top: 50%;
      text-transform: uppercase;
      transform: translateY(-50%);
      &:hover {
        background: var(--primaryClr);
        border-color: var(--primaryClr);
        color: var(--blackClr);
      }
    }
    h3 {
      font-size: 16px;
      margin: 0;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
  ul {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-top: 12px;
    padding-bottom: 4px;
    justify-content: space-between;
    li {
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      width: 170px;
      img {
        height: 78px;
        width: 100%;
      }
    }
  }
}

.populaGamesSection {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    padding: 0;
    justify-content: flex-start;

    li {
      width: 14.99% !important;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.CasinoSection {
  ul {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: auto;
    grid-row-gap: 2px;
    grid-column-gap: 2px;
    grid-template-columns: repeat(auto-fit, 117px);
    li {
      width: 117px;
      height: 70px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        img {
          height: 70px;
        }
      }
    }
  }
}

.favicon img {
  width: 150px;
}

.fastgameMob a {
  background: transparent !important;
  padding: 0 !important;
  height: auto !important;
}
.mobleftbarPage {
  .upperbalnace {
    background-color: var(--headClr);
    padding: 15px;
    .balance {
      color: var(--tertiory1);
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      img {
        margin-right: 14px;
        width: 24px;
      }
    }
    .balance-amount {
      color: var(--tertiory1);
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      border-radius: 25px;
      border-color: var(--whiteClr);
      color: var(--whiteClr);
      &.btn-outline-secondary {
        border-color: var(--whiteText);
        color: var(--whiteText);
      }
      &.btn_withdraw {
        color: var(--whiteClr);
        width: 100%;
        border-color: var(--whiteClr);
      }
      &.btn_primary {
        background: var(--primaryClr);
        border-color: var(--primaryClr);
        color: var(--blackClr);
        width: 100%;
      }
    }
    .col-6 {
      line-height: initial;
    }
  }
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--headClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--myProfileHeadText);
    img {
      width: 48px;
      height: 48px;
      border: 0;
      padding: 11px;
    }
    svg {
      fill: gold;
    }
  }
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user_name,
    p {
      color: var(--secondary4);
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
    filter: brightness(0.5);
    &:focus {
      box-shadow: none;
    }
  }
  .profilemobsection {
    padding: 0 10px 10px;
  }
}

.SocialMediaSection {
  display: none !important;
  ul {
    display: flex;
    gap: 8px;
    margin: 0 0 30px;
    flex-wrap: wrap;
    li {
      width: 24%;
      border: 1px solid var(--headClr);
      border-radius: 10px;

      a {
        color: var(--headClr);
        white-space: nowrap;
        padding: 10px;
        display: block;
        font-size: 15px;
        font-weight: 600;
        img {
          width: 25px;
          margin-right: 15px;
        }
      }
    }
  }
}

.head-social {
  display: none;
}
.afterlogin {
  .headerRight {
    .searchbar {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}
// .searchGames {
//   display: flex;
//   // margin-inline: 5px !important;
//   border-radius: 16px;
//   background: #000000;
//   border: 1px solid goldenrod;
//   align-items: center;
//   justify-content: center;
//   padding: 5px 8px;
//   cursor: pointer;
//   p {
//     margin-right: 8px;
//     margin-bottom: 0;
//   }
// }
.GamesShow {
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
  justify-content: center;
  align-items: center;
  .displayGames {
    width: 47%;
    margin-bottom: 30px;
    box-shadow: 0px 3px 5px 5px #013862;
  }
}
.searchGames {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005ca2;
  gap: 10px;
  padding: 4px 12px;
  border-radius: 20px;
  cursor: pointer;
  @media (max-width: 1199.98px) {
    font-size: large;
    padding: 4px 4px !important;
    border-radius: 50%;
  }
  p {
    margin: 0 !important;
    @media (max-width: 1199.98px) {
      display: none;
    }
  }
  svg {
    font-size: large;
  }
}
.login-modal {
  .modal-content {
    width: 95%;
    justify-content: center;
    // height: 95vh;
    margin: 0 auto;
    .modal-header {
      button {
        color: #000;
      }
    }
  }
}
.home-iframe {
  margin: 15px 0 0;
  iframe {
    width: 100%;
    height: 400px !important;
  }
}

.login-modal {
  .modal-header,
  .offcanvas-header {
    position: relative;
    .modal-title.h4,
    .top-heading {
      color: rgb(1, 56, 98);
      width: 100%;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.main.gamePageSection {
  min-height: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media (max-width: 991px) {
  // .main.gamePageSection {
  //   padding-top: 45px !important;
  // }
}

.WhatsappIconFixed {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 9;
  a {
    display: flex;
    color: var(--whiteClr);
    background: #ffffff;
    border-radius: 50px;
    padding: 5px;
    align-items: center;
    width: 60px;
    p {
      margin: 0;
      padding-left: 10px;
      font-size: 14px;
      font-weight: 700;
      padding-right: 5px;
      &:last-child {
        text-decoration: underline;
      }
    }
  }
}

.ThankyouImg {
  position: relative;
  height: 100vh;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.PlayBtnSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
  .PlayBtn {
    position: absolute;
    bottom: 75px;
    margin: 0 auto;
    background-color: var(--primaryClr);
    color: var(--blackClr);
    border-radius: 5px;
    text-align: center;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 35px;
    font-weight: 700;
    &:hover {
      background-color: var(--whiteClr) !important;
      color: var(--blackClr) !important;
    }
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 6px solid #ffdd00;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }

  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;

      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
          color: #fff;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: #023f6e !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          background: #2c2f3d;
          padding-top: 10px;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: #023f6e;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #01131d;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1, 1);
    background: yellow;
  }
  50% {
    transform: scale(1.1, 1.1);
    background: darkred;
    color: #fff;
    box-shadow: inset 0px 2px 4px 0px #1b00ff;
  }
  100% {
    transform: scale(1, 1);
    background: blue;
  }
}

.top4_banners {
  display: flex;
  gap: 6px;
  div {
    width: 100%;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    p {
      background: #000;
      color: #fff;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 0 !important;
      font-size: 16px;
    }
  }
}
.top4_banners2 {
  display: flex;
  gap: 6px;
  div {
    width: 100%;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    p {
      background: #000;
      color: #fff;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 0 !important;
      font-size: 16px;
    }
  }
}
.banner5 {
  p {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0 !important;
    font-size: 16px;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
    background: yellow;
  }
  50% {
    transform: scale(1.1, 1.1);
    background: darkred;
    color: #fff;
    box-shadow: inset 0px 2px 4px 0px #1b00ff;
  }
  100% {
    transform: scale(1, 1);
    background: blue;
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}

.providerPage {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  a {
    width: 20.3%;
    h5 {
      background: #000;
      color: white;
      display: flex;
      justify-content: center;
      padding: 4px;
      border-radius: 0 0 6px 6px;
    }
  }
}
.allcasino {
  background: #023f6e;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  .sectionTitle {
    color: #fff !important;
    margin: 5px 0;
  }
  .back-link {
    margin-top: 0 !important;
  }
}
.valuesDeposit {
  border: 1px var(--headClr) solid;
  margin-top: 20px;
  color: var(--headClr);
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  /* width: 50%; */
  justify-content: center;
  h3 {
    font-weight: 400;
  }
  .copyValue {
    font-size: 20px;
    margin-left: 30px;
    text-align: center;
    div {
      font-size: 8px;
      font-weight: 700;
    }
  }
}
.qrSize {
  width: 250px;
}

.WithDrawSection {
  .WithDrawWalletDetails {
    background-color: #021622;
    background-color: #021622;
    background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 15px;
    .deposit-head {
      width: 30%;
      margin: 0 10px;
      background: var(--bodyClr);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .deposit-img {
        max-width: 300px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.bkash {
          width: 70%;
        }
      }
      .deposit-name {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2c2f3d;
        color: var(--whiteClr);
        height: 38px;
        margin-top: 20px;
        p {
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }
}
.DepositModal {
  .modal-title {
    img {
      height: 50px;
    }
  }
  .modal-body {
    background-color: var(--headClr);
    .deposit-modal-content {
      p {
        color: var(--whiteClr);
      }
      .confirmBtn {
        .btn-primary {
          background: linear-gradient(
            100deg,
            rgb(6, 99, 177) 0%,
            rgb(40, 131, 239) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;
        }
      }
    }
  }
}

.AccountWithdraw {
  .AccountWithdrawSection {
    background-color: var(--headClr);
    padding: 20px 20px;
    .copy-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--whiteClr);
      border: 1px solid var(--whiteClr);
      border-radius: 5px;
      padding: 8px;
      p {
        margin: 0;
        svg {
          width: 18px;
          margin-left: 6px;
        }
      }
      h5 {
        margin: 0;
      }
    }
    .RequestSection {
      background-color: var(--headClr);
      .sectionTitle {
        color: var(--whiteClr);
      }
      .payment-button {
        display: flex;
        align-items: center;
        .btn-primary {
          background: linear-gradient(
            100deg,
            rgb(6, 99, 177) 0%,
            rgb(40, 131, 239) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          margin-right: 10px;
        }
        .btn-danger {
          background-color: #b02a37;
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          margin-right: 10px;
        }
      }
    }
  }
}

.Contact {
  ul {
    display: flex;
    flex-direction: column;

    li {
      display: block !important;
      background: #005ca2;
      padding: 8px;
      border-radius: 5px;
      a {
        display: flex;
        align-items: center;
        p {
          margin: 0;
          color: var(--whiteClr) !important;
          font-size: 18px !important;
        }
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
    }
  }
}
.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-heading {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      margin: 0.5%;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 19%;
      &:hover .casino_overlay {
        inset: 0 0 0 0;
        img {
          height: 80px !important;
          width: 80px !important;
        }
      }
      img {
        border-radius: 8px;
        width: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgba(37, 35, 152, 0.49),
          rgba(37, 35, 152, 0.49)
        );
        border-radius: 50%;
        content: "";
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 10px;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}

.casino-games {
  background-color: var(--bodyClr);
  @media (max-width: 991.98px) {
    background: var(--blackClr);
  }
  .filter-box {
    background: var(--whiteClr);
    width: 100%;
    @media (max-width: 991.98px) {
      background: var(--blackClr);
      width: 94.6666666667vw;
      margin: 0 auto;
    }
    .inner-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .searchbar {
        width: fit-content;
        display: flex;
        align-items: center;
        img {
          margin-right: 7px;
        }
        .form-control {
          background: #f2f2f2 !important;
          color: #0088da !important;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 1px;
          width: auto;
          padding: 7px 10px;
          transition: background 0.3s ease-in-out 0.1s,
            box-shadow 0.5s ease-in-out, border 0.3s ease-in-out 0.1s;
          border-color: transparent;
          border-radius: 5px !important;
          outline: 0;
          box-shadow: 0 0.125rem 0.3125rem #e6e6e6 inset;
          &::placeholder {
            color: #919191;
            transition: all 0.3s;
          }
          &:focus,
          &:hover {
            border: 1px solid #0088da;
            &::placeholder {
              color: #616161;
            }
          }
        }
      }
      .sort-games {
        height: 57px;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        li {
          .btn-sort {
            color: rgba(0, 0, 0, 0.7);
            font-size: 14px;
            height: 57px;
            line-height: 57px;
            display: inline-block;
            padding: 0 10px;
            margin-left: 3px;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            position: relative;
            &::before {
              position: absolute;
              content: "";
              bottom: 0;
              left: 0;
              width: 100%;
              height: 0;
              transition: height 0.3s ease-in-out;
              background: #0088da;
            }
            &:hover,
            &.active {
              color: #0088da;
              &::before {
                height: 3px;
              }
            }
          }
        }
      }
      &.mobile {
        h3 {
          color: var(--whiteClr);
          font-size: 4vw;
          font-weight: 700;
          padding: 2.6666666667vw 0;
          margin-bottom: 0;
          position: relative;
          &:before {
            content: "";
            display: inline-block;
            width: 1.0666666667vw;
            height: 4vw;
            margin-right: 1.3333333333vw;
            background: #0088da;
            vertical-align: middle;
          }
        }
        .dropdown {
          width: 27.7666666667vw;
          .dropdown-toggle {
            background: #262626;
            color: var(--whiteClr);
            font-size: 3.2vw;
            width: 100%;
            padding: 1.0666666667vw 2.6666666667vw;
            border: 0;
            border-radius: 0.8vw;
          }
          .dropdown-menu {
            background: #262626;
            width: 27.7666666667vw;
            min-width: 27.7666666667vw;
            padding: 0;
            transition: all 0.3s;
            border: 0;
            .dropdown-item {
              color: var(--whiteClr);
              font-size: 3.2vw;
              display: block;
              padding: 1.401vw 2.4vw;
              &:first-child {
                border-radius: 0.8vw 0.8vw 0 0;
              }
              &:last-child {
                border-radius: 0 0 0.8vw 0.8vw;
              }
              &.active {
                background: #18a8ff;
              }
            }
          }
        }
      }
    }
  }
  .more-filter {
    background-image: linear-gradient(0deg, #0088da, #0078c1 100%);
    margin-bottom: 5px;
    @media (max-width: 991.98px) {
      background: var(--blackClr);
      padding-bottom: 5px;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .form-check {
        width: 150px;
        height: 44px;
        line-height: 44px;
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
        cursor: pointer;
        transition: transform 0.3s ease-out 0.1s, background 0.3s ease-in 0.1s,
          -webkit-transform 0.3s ease-out 0.1s;
        @media (max-width: 991.98px) {
          height: 35px;
          line-height: 35px;
        }
        &:hover {
          background: #feda00;
        }
        .form-check-input {
          width: 20px;
          height: 20px;
          margin-top: 0;
          margin-left: 15px;
          border: 0 !important;
          border-radius: 3px;
          transition: transform 0.3s ease-out 0.1s, background 0.3s ease-in 0.1s,
            -webkit-transform 0.3s ease-out 0.1s;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05),
            0 2px 3px 0 rgba(0, 0, 0, 0.05), 0 3px 4px 0 rgba(0, 0, 0, 0.05);
          @media (max-width: 991.98px) {
            margin-left: 10px;
          }
        }
        .form-check-input.is-valid,
        .was-validated,
        .form-check-input:valid {
          border: 0 !important;
          transition: transform 0.3s ease-out 0.1s, background 0.3s ease-in 0.1s,
            -webkit-transform 0.3s ease-out 0.1s;
        }
        .form-check-input.is-valid:checked,
        .was-validated .form-check-input:valid:checked {
          background-color: #ffe332;
          border: 0 !important;
        }
        .form-check-input.is-valid:checked + .form-check-label:not(:hover),
        .was-validated
          .form-check-input:valid:checked
          + .form-check-label:not(:hover) {
          color: #ffe332;
        }
      }
      .form-check-label {
        color: var(--whiteClr);
        font-size: 14px;
        width: calc(100% - 55px);
        display: inline-block;
        margin-left: 16px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s;
        @media (max-width: 991.98px) {
          width: calc(100% - 38px);
          margin-left: 8px;
        }
      }
    }
    .all-filters {
      border-radius: 0 0 5px 5px;
      .filter-heading {
        background: rgba(255, 255, 255, 0.2);
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        p {
          color: #ffdf18;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      .providers {
        background: rgba(0, 0, 0, 0.2);
        .filters {
          display: flex;
          justify-content: space-between;
          @media (max-width: 991.98px) {
            flex-direction: column;
          }
        }
      }
      .all-categories {
        background: rgba(0, 0, 0, 0.2);
        .form-check {
          transition: transform 0.3s ease-out 0.1s, background 0.3s ease-in 0.1s,
            -webkit-transform 0.3s ease-out 0.1s;
          &:hover {
            background: #feda00;
          }
        }
      }
    }
    .more-btn {
      .btn {
        background-image: linear-gradient(180deg, #0098f4 0, #004874 200%);
        color: #ffdf18;
        font-size: 14px;
        height: 100%;
        line-height: 44px;
        padding: 0 40px;
        border: 0;
        border-radius: 0;
        transition: background-position 0.3s ease-in,
          text-shadow 0.3s ease-in-out 0.1s;
        @media (max-width: 991.98px) {
          background-image: linear-gradient(180deg, #0098f4 0%, #004874 200%);
          line-height: normal;
          width: 98%;
          padding: 4.5px 15px;
          margin-left: 1%;
          border-radius: 5px;
        }
        &:hover {
          background-image: linear-gradient(180deg, #0098f4 -90%, #004874 90%);
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .hide-items {
      .btn {
        background-image: linear-gradient(180deg, #0098f4 0%, #004874 200%);
        color: #ffdf18;
        font-size: 14px;
        width: 100%;
        padding: 4.5px 15px;
        margin-bottom: 5px;
        border: 0;
        border-radius: 0 0 5px 5px;
        transition: background-position 0.3s ease-in,
          text-shadow 0.3s ease-in-out 0.1s;
        @media (max-width: 991.98px) {
          width: 98%;
          margin-left: 1%;
          margin-bottom: 0;
          border-radius: 5px;
        }
        &:hover {
          background-image: linear-gradient(180deg, #0098f4 -90%, #004874 90%);
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .game-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
    margin: 10px;
    box-shadow: 0 1px 3px 0 rgb(62 62 62),
      0 1px 2px -1px rgba(226, 232, 240, 0.1);
    padding: 6px;
    background: #3e3e3e;
    @media only screen and (max-width: 767.98px) {
      box-shadow: none;
      padding: 0;
      margin: 5px 10px;
    }
  }
  .categories {
    display: flex;
    overflow-x: auto;
    background: #3e3e3e;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #3e3e3e;
    }
    &::-webkit-scrollbar-thumb {
      background: #4e4e4e;
    }
    li {
      margin-right: 10px;
      cursor: pointer;
      a {
        min-width: max-content;
        padding: 5px 10px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        background-color: var(--secondaryClr);
        img {
          width: 20px;
          height: auto;
          margin-right: 5px;
        }
        span {
          color: var(--whiteClr);
        }
        &.active {
          background: #0088da;
          span {
            color: var(--whiteClr);
          }
        }
      }
    }
  }
  .providers {
    .dropdown-toggle {
      background: #4e4e4e;
      border-color: #4e4e4e;
      border-radius: 3px;
    }
    .dropdown-menu {
      background: #2d2d2d;
      padding: 5px;
      border: 0;
      border-radius: 3px;
      .dropdown-item {
        background: #4e4e4e;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        padding: 8px 10px;
        margin-bottom: 2px;
        border-bottom: 1px solid #1e293b;
        border-radius: 3px;
        &:last-child {
          border-bottom: 0;
        }
        &:hover,
        &:focus {
          background: var(--bodyClr);
          color: var(--blackClr);
        }
      }
    }
  }
  .all-images {
    padding: 15px;
    @media only screen and (max-width: 767.98px) {
      padding: 10px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      background: transparent !important;
      gap: 1rem;
      li {
        width: calc(16.66% - 0.833rem);
        height: auto !important;
        flex: inherit !important;
        position: relative;
        border-radius: 8px;
        background-color: #2d2d2d;
        @media only screen and (max-width: 767.98px) {
          width: calc(50% - 0.5rem);
        }
        a {
          border-radius: 8px;
          font-weight: 300;
          transition: 0.4s;
          // position: relative;
          display: block;
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 2px 0 rgba(45, 45, 45, 0.1),
            0 4px 4px 0 rgba(49, 49, 49, 0.1), 0 8px 8px 0 rgba(42, 42, 42, 0.1),
            0 16px 16px 0 rgba(32, 32, 32, 0.1);
          .hover_Name {
            color: #fff !important;
            font-size: 18px !important;
            left: 50%;
            line-height: 24px;
            position: absolute;
            text-shadow: 0 2px 2px rgba(32, 32, 32, 0.86);
            text-transform: uppercase;
            top: 68%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          img {
            width: 100%;
            height: auto;
            border-radius: 3px;
            &.play_button {
              display: none;
            }
          }
          p {
            font-size: 0.875rem;
            width: 90%;
            color: rgb(226 232 240 / 0.5);
            text-align: center;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            &.hover_Name {
              font-size: 14px !important;
              font-weight: 900;
              line-height: 20px;
              width: 90%;
              display: none;
            }
          }
          &:hover {
            img {
              -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
              filter: blur(4px);
              &.play_button {
                filter: none;
                display: block;
                position: absolute;
                top: 20%;
                width: 20%;
                height: auto;
                left: 40%;
              }
            }
            .hover_Name {
              display: block;
            }
          }
        }
        .game-inner {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(0deg, #000 -20%, transparent 40%);
          border-radius: 3px;
          box-shadow: inset 0 0 0 1px rgba(14, 164, 255, 0.2),
            inset 0 0 10px 0 rgba(255, 255, 255, 0.1);
          .game-group {
            position: absolute;
            z-index: 4;
            bottom: 0;
            width: 100%;
            padding: 0 10px 10px;
            &::after {
              position: absolute;
              content: "";
              left: 0;
              bottom: 0;
              display: inline-block;
              z-index: 2;
              width: 100%;
              height: 3.375rem;
              background-image: linear-gradient(
                0deg,
                rgba(0, 136, 218, 0.5) 2%,
                transparent 100%
              );
              border-radius: 0 0 3px 3px;
            }
            h4 {
              color: #fff;
              font-size: 14px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-height: 20px;
              text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
              margin-bottom: 0;
            }
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          z-index: 6;
          opacity: 0;
          overflow: hidden;
          transition: opacity 0.3s ease-in 0.1s, padding-top 0.3s ease-in 0.1s;
          box-shadow: inset 0 0 1px 1px rgba(14, 164, 255, 0.4),
            inset 0 0 10px 0 rgba(255, 255, 255, 0.2);
          .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            padding-top: 120%;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in 0.1s;
            a {
              background: linear-gradient(
                to right,
                #facd04 0,
                #fbc103 15%,
                #fbc103 30%,
                #facd04 55%,
                #facd04 100%
              );
              color: var(--whiteClr);
              font-size: 14px;
              text-align: center;
              width: 120px;
              height: 35px;
              line-height: 35px;
              cursor: pointer;
              margin-bottom: 10px;
              border-radius: 3px;
              transition: box-shadow 0.5s ease-out 0.1s,
                background-position 0.3s ease-in, text-shadow 0.3s ease-in 0.1s;
              box-shadow: 0 5px 10px 0 rgba(6, 8, 15, 0.1),
                inset 0 0 3px 0 #ffdd8f;
            }
          }
        }
        &:hover {
          .overlay {
            opacity: 1;
            .play-btn {
              padding-top: 0;
              a {
                text-shadow: 0 1px 3px #967b02;
                box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
                  0 4px 4px 1px rgba(6, 8, 15, 0.1),
                  0 8px 8px 1px rgba(6, 8, 15, 0.1),
                  0 16px 16px 1px rgba(6, 8, 15, 0.1),
                  0 32px 32px 1px rgba(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
              }
            }
          }
        }
      }
    }
  }
}
.all-images li a ~ .focus-border::after,
.all-images li a ~ .focus-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #f8fcff;
  transition: 0.3s;
}
.all-images li a ~ .focus-border::after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.all-images li a ~ .focus-border i::after,
.all-images li a ~ .focus-border i::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #f8fcff;
  transition: 0.4s;
}
.all-images li a ~ .focus-border i::after {
  left: auto;
  right: 16px;
  top: auto;
  bottom: 11px;
}
.all-images li a:hover ~ .focus-border i::after,
.all-images li a:hover ~ .focus-border i::before {
  height: 91%;
  transition: 0.4s;
}
.all-images li a:hover ~ .focus-border::after {
  width: 90% !important;
}
.all-images li a:hover ~ .focus-border i::before {
  top: 10px;
  left: 13px;
}
.all-images li a:hover ~ .focus-border::before {
  top: 10px;
  left: 14px;
}
.all-images li a:hover ~ .focus-border::after {
  bottom: 10px;
  right: 16px;
}
.all-images li a:hover ~ .focus-border::after,
.all-images li a:hover ~ .focus-border::before {
  width: 90%;
  transition: 0.3s;
}
.MobileGamesSection.slots-sectionPage {
  margin: 25px 75px;
  .HeadingSection {
    background-color: var(--bgSecondary);
    padding: 8px;
  }
  .SlotsTabsSection {
    ul {
      display: flex;
      height: 40px;
      li {
        flex: 1;
        margin: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2d2d2d;
        color: var(--whiteClr);
        cursor: pointer;
        &:hover {
          background: #0088da;
        }
      }
    }
  }
  .SearchBar {
    display: none;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    a.casino_games {
      width: 16.2%;
      height: 160px;
      margin: 2px;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      .shadow.rounded.position-relative {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;
        .textslideright {
          display: none;
        }
      }
      img {
        width: 100%;
        transition: 0.6s transform ease;
        height: 100% !important;
      }
      &:hover {
        img {
          transform: scale(1.13);
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .MobileGamesSection.slots-sectionPage {
    margin: 25px 2px !important;
  }
  .MobileGamesSection.slots-sectionPage ul a.casino_games {
    width: 48.8% !important;
  }
  .RegisterPageSection
    .RegisterPage
    .steps-canvas
    .form-steps
    form
    > div
    label {
    padding-left: 9px;
  }
  body
    .RegisterPageSection
    .RegisterPage
    .verify-number
    .react-tel-input
    input.form-control {
    padding: 0 0 0 32px !important;
  }
  body
    .RegisterPageSection
    .RegisterPage
    .verify-number
    .react-tel-input
    .selected-flag {
    padding: 0 0 0 4px !important;
  }
  .SignupSection {
    display: flex;
    align-items: center;
    background-color: #222222;
    border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3) !important;
    a {
      border: 0;
      padding-left: 30px;
      height: 45px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        display: block;
      }
    }
  }
  .footer .footer-links {
    width: 100% !important;
    padding-bottom: 45px;
  }
  .footer .footer-links ul {
    flex-wrap: wrap !important;
  }
  .footer .footer-links ul li a {
    font-size: 11px !important;
  }
}

.ForgotPasswordSection {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}
@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
.LoginBack {
  text-align: right !important;
}

.app_download_page {
  .section {
    background-color: #0f192e;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 773px;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: top;
    @media (max-width: 1199.98px) {
      height: auto;
    }
    @media (max-width: 767.98px) {
      padding: 0 15px;
    }
    &.download {
      background-image: url("./assets/images/appDownload/bg-download-sec.jpg");
      .section-inner {
        .col-lg-6 {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .section-inner {
      text-align: left;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      p {
        color: #fff;
        font-size: 18px;
        font-weight: lighter;
        letter-spacing: 0.0625rem;
        line-height: 30px;
        text-align: left;
        margin-bottom: 0;
        @media (max-width: 1199.98px) and (min-width: 576px) {
          line-height: 26px;
        }
        @media (max-width: 575.98px) {
          font-size: 15px;
          line-height: 22px;
        }
        + p {
          margin-top: 15px;
        }
      }
      .sub-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 70px;
        display: inline-flex;
        margin: 40px 0;
        @media (max-width: 1199.98px) {
          font-size: 16px;
          flex-wrap: wrap;
          margin: 20px 0 10px;
        }
        .download-btn {
          background-image: url("./assets/images/appDownload/download-btn-bg.png");
          width: 275px;
          height: 77px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          color: #000;
          font-size: 27px;
          font-weight: 400;
          line-height: 70px;
          text-align: center;
          text-transform: uppercase;
          display: block;
          margin-right: 20px;
          @media (max-width: 1199.98px) {
            font-size: 19px;
            font-weight: 600;
            width: 210px;
            height: 60px;
            line-height: 58px;
            margin-right: 15px;
          }
        }
        span {
          display: inline-block;
          max-width: 100%;
          padding-right: 10px;
          @media (max-width: 1199.98px) {
            line-height: 56px;
            padding-right: 0;
          }
          a {
            color: #fce45b;
            display: inline;
            max-width: 100%;
            border-bottom: 2px solid #b1934e;
          }
        }
      }
      .icon-box {
        display: block;
        width: 100%;
        li {
          display: flex;
          flex-flow: wrap;
          flex-direction: row;
          justify-content: center;
          align-content: flex-start;
          align-items: flex-start;
          width: 100%;
          position: relative;
        }
        .icon-banner {
          position: relative;
          width: 600px;
          height: 380px;
          margin: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          @media (max-width: 1199.98px) {
            width: 481px;
          }
          @media (max-width: 991.98px) {
            height: 320px;
          }
          @media (max-width: 575.98px) and (min-width: 360px) {
            width: 330px;
            height: 220px;
          }
          @media (max-width: 359px) {
            width: 290px;
            height: 190px;
          }
          &.sport {
            background-image: url("./assets/images/appDownload/exp-sport.png");
            left: 1.9375rem;
            @media (max-width: 991.98px) {
              left: 0;
            }
          }
          &.slot {
            background-image: url("./assets/images/appDownload/exp-slots.png");
            left: -1.9375rem;
            @media (max-width: 1199.98px) {
              left: -0.8rem;
            }
            @media (max-width: 991.98px) {
              left: 0;
            }
          }
          &.casino {
            background-image: url("./assets/images/appDownload/exp-casino.png");
            left: 1.9375rem;
            @media (max-width: 991.98px) {
              left: 0;
            }
          }
          &.table {
            background-image: url("./assets/images/appDownload/exp-table.png");
            left: -1.9375rem;
            @media (max-width: 1199.98px) {
              left: -0.8rem;
            }
            @media (max-width: 991.98px) {
              left: 0;
            }
          }
          .title {
            position: relative;
            top: 5.8125rem;
            left: 1.875rem;
            p {
              position: absolute;
              top: 1.75rem;
              left: 0.1875rem;
              color: #fce35a;
              font-size: 2.375rem;
              line-height: 1.25rem;
              text-transform: uppercase;
              @media (max-width: 1199.98px) and (min-width: 576px) {
                font-size: 1.75rem;
                top: 2.5rem;
              }
              @media (max-width: 991.98px) {
                top: 1rem;
              }
              @media (max-width: 575.98px) {
                font-size: 1.25rem;
                top: -1.5rem;
                left: -0.5rem;
              }
              @media (max-width: 359px) {
                left: -1rem;
                top: -2rem;
              }
            }
            span {
              position: absolute;
              top: 5.125rem;
              left: 0.1875rem;
              width: 17.1875rem;
              color: #e7e4e4;
              font-size: 1.75rem;
              font-weight: lighter;
              line-height: 2rem;
              @media (max-width: 1199.98px) and (min-width: 576px) {
                font-size: 1.5rem;
                width: 15.1875rem;
              }
              @media (max-width: 991.98px) {
                top: 3.75rem;
              }
              @media (max-width: 575.98px) {
                top: 1rem;
                left: -0.5rem;
                font-size: 1.15rem;
                width: 11rem;
                line-height: 1.5rem;
              }
              @media (max-width: 359px) {
                top: 0;
                left: -1rem;
              }
            }
          }
        }
      }
    }
    .download-title {
      text-align: center;
      padding: 40px 0;
      @media (max-width: 1199.98px) and (min-width: 576px) {
        padding: 20px 0;
      }
      @media (max-width: 575.98px) {
        padding: 0;
      }
      h1 {
        line-height: 61px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        padding: 8px 0;
        margin-bottom: 0;
        position: relative;
        strong {
          color: #fce35a;
          font-size: 52px;
          font-weight: 700;
          padding-right: 5px;
          @media (max-width: 575.98px) {
            font-size: 26px;
          }
        }
        p {
          color: #fce35a;
          font-size: 33px;
          font-weight: 400;
          margin-bottom: 0;
          @media (max-width: 575.98px) {
            font-size: 18px;
          }
        }
      }
      h2 {
        color: #e7e4e4;
        font-size: 36px;
        font-weight: 400;
        text-align: left;
        line-height: 42px;
        padding: 8px 0;
        margin-bottom: 0;
        @media (max-width: 575.98px) {
          font-size: 26px;
        }
      }
      h3 {
        color: #fce35a;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.5em;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: flex-end;
        padding: 8px 0;
        margin-bottom: 0;
        @media (max-width: 1199.98px) and (min-width: 768px) {
          font-size: 32px;
        }
        @media (max-width: 767.98px) {
          font-size: 20px;
        }
      }
      h4 {
        color: #e7e4e4;
        font-size: 35px;
        font-weight: lighter;
        line-height: 42px;
        padding: 8px 0;
        position: relative;
        margin-bottom: 0;
        @media (max-width: 767.98px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    &.experience {
      background-image: url("./assets/images/appDownload/bg-experience-sec.jpg");
      height: 100%;
      @media (max-width: 1199.98px) and (min-width: 992px) {
        height: 773px;
      }
      .download-title {
        padding-top: 15px;
      }
      .section-inner {
        .icon-box {
          li {
            &:nth-child(1) {
              top: -1.875rem;
              @media (max-width: 1199.98px) and (min-width: 992px) {
                top: -2.875rem;
              }
              @media (max-width: 991.98px) {
                top: 0;
              }
            }
            &:nth-child(2) {
              top: -7.5rem;
              @media (max-width: 1199.98px) and (min-width: 992px) {
                top: -12.5rem;
              }
              @media (max-width: 991.98px) {
                top: 0;
              }
            }
          }
        }
      }
    }

    &.features {
      background-image: url("./assets/images/appDownload/bg-features-sec.jpg");
      overflow: hidden;
      .download-title {
        padding-top: 60px;
        @media (max-width: 1199.98px) and (min-width: 768px) {
          padding-top: 40px;
        }
        @media (max-width: 767.98px) {
          padding-top: 10px;
        }
        h3 {
          p {
            margin-bottom: 0;
            strong {
              font-size: 60px;
              font-weight: 700;
              padding-right: 5px;
              @media (max-width: 1199.98px) {
                font-size: 38px;
                padding-right: 0;
              }
              @media (max-width: 767.98px) {
                font-size: 26px;
              }
            }
          }
        }
      }
      .section-inner {
        .left-img,
        .right-img {
          @media (min-width: 768px) {
            width: 28%;
          }
        }
        .middle-sec {
          @media (min-width: 768px) {
            width: 44%;
          }
        }
        .left-img {
          position: relative;
          bottom: 0.25rem;
          /* left: 1.5625rem; */
          img {
            width: 100%;
          }
        }
        .right-img {
          position: relative;
          top: 2.25rem;
          /* right: 1.5625rem; */
        }
        .icon-box {
          display: block;
          width: 100%;
        }
        li {
          display: inline-block;
          position: relative;
          width: 33.33%;
          height: auto;
          margin: 0;
          padding: 0.75rem 0;
          color: #e7e4e4;
          text-align: center;
          vertical-align: top;
        }
        img {
          width: 90%;
        }
        span {
          font-size: 1.1875rem;
          font-weight: 300;
          line-height: 1.4375rem;
          @media (max-width: 575.98px) {
            font-size: 0.95rem;
            line-height: 1rem;
          }
        }
      }
    }
  }
}

.ezugi_iframe,
.exchange_iframe {
  height: calc(100vh - 145px);
  overflow: hidden;
  margin-top: 145px;
  @media (max-width: 991.98px) {
    height: calc(100vh - 13.3333333333vw);
    margin-top: 13.3333333333vw;
  }
  iframe {
    @media (max-width: 991.98px) {
      height: calc(100vh - 13.3333333333vw) !important;
    }
    @media only screen and (min-device-width: 280px) and (max-device-width: 991.98px) {
      height: calc(100vh - 13.3333333333vw) !important;
      height: calc(var(--vh, 1vh) * 100 - 13.3333333333vw) !important;
    }
  }
}
