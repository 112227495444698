@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

::-webkit-scrollbar {
  height: 7px;
  width: 9px !important;
  border-radius: 0 !important;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-corner {
  background: #023f6e;
}
::-webkit-scrollbar-track {
  background-color: #4e4e4e;
  border-radius: 0 !important;
}
img {
  max-width: 100%;
}
.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.live_heading {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  background: -webkit-linear-gradient(
    top,
    #8f6b29,
    #fde08d,
    #df9f28
  ) !important;
  background-clip: text !important;
  -webkit-text-fill-color: #ffc63f70 !important;
  font-family: Arial, Arial, Tahoma, Serif;
  margin: 25px 0px 20px 0px;
}
.Evolution_Games {
  display: flex;
  justify-content: start;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  scrollbar-color: #132d44 #1c2634;
}
.listings {
  position: relative;
}
.listings .right-arrow {
  position: absolute !important;
  top: 0% !important;
  right: 0 !important;
  left: auto !important;
}
.listings .left-arrows svg {
  position: ABSOLUTE;
  height: 35px;
  width: 35px;
  color: gold;
  top: 110px;
  left: -2px;
}
.listings .left-arrows {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 1px;
  z-index: 9;
}
.Provider_Games .shadow.rounded.position-relative {
  width: 265px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.Payments_Games .shadow.rounded.position-relative {
  width: 260px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.shadow.rounded.position-relative {
  width: 285px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.text {
  font-size: 26px;
  font-weight: 900;
  margin: 40px 0px 20px 0px;
  background: -webkit-linear-gradient(
    top,
    #8f6b29,
    #fde08d,
    #df9f28
  ) !important;
  background-clip: border-box;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #ffc63f70 !important;
  font-family: Arial, Arial, Tahoma, Serif;
}
.Provider_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #0a0f19;
  padding: 10px;
  border-radius: 10px;
}
.Provider_Games .shadow.rounded.position-relative img {
  margin: 0px auto;
  width: 80%;
}
.Payments_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.text-left {
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
}
._container_1ib6x_1 {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 90px !important;
  width: 100%;
}
#scroller {
  margin-top: 90px;
}
.slick-next {
  right: 15px !important;
}
.slick-prev {
  left: 15px !important;
  z-index: 9;
}
.support_icons {
  width: 26px;
  height: 26px;
}
.social_icons {
  width: 32px;
  border-radius: 50px;
}
.text-center.mb-4 {
  font-weight: 100;
  color: goldenrod;
  background: #0e1422;
  border-radius: 5px;
  padding: 10px 0px;
}
.Toastify {
  position: relative !important;
  z-index: 9999999999 !important;
}
img.provider-img {
  width: 78px;
}
.privacy_policy {
  padding-bottom: 71px;
}
.withdraw_btn a.btn_primary.btn-primary {
  padding: 9px 30px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 120px;
  margin-left: 9px;
}
.email_contact {
  font-size: large;
}

@media only screen and (max-width: 767px) {
  .live_casino_page.live {
    padding-top: 57px;
  }
  .RegisterPageSection {
    margin: 0 !important;
    padding: 0 !important;
    z-index: 999;
    border: 0 !important;
  }
  .RegisterPageSection .RegisterPage .signupimg {
    display: none !important;
  }
  .RegisterPageSection .RegisterPage .login-modal {
    width: 100% !important;
    margin: 0 !important;
  }
  .RegisterPageSection .RegisterPage span.top-heading {
    background-color: #222222 !important;
    border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3) !important;
    height: 45px !important;
    margin: 0 !important;
    color: var(--whiteClr) !important;
  }
  .steps-canvas .form-steps {
    background-color: #242424 !important;
    padding: 15px !important;
  }
  .steps-canvas .form-steps form {
    background-color: #181818;
  }
  .steps-canvas .form-steps form > div {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3) !important;
  }
  .RegisterPageSection
    .RegisterPage
    .steps-canvas
    .form-steps
    form
    > div
    input {
    width: 100% !important;
    height: 13.3333333333vw !important;
    border: 0;
    outline: none;
    background: #181818 !important;
    color: #fff !important;
    font-size: 3.4666666667vw;
    box-sizing: border-box;
    overflow-x: auto;
    padding: 0 15px !important;
  }
  .RegisterPageSection
    .RegisterPage
    .steps-canvas
    .form-steps
    form
    > div
    label {
    width: 50% !important;
    font-size: 12px !important;
  }
  .offcanvas-body {
    padding: 1rem !important;
  }
  .steps-canvas .form-steps form > div {
    padding: 0 !important;
  }
  .steps-canvas .form-steps form > div.d-flex.justify-content-end {
    border: 0 !important;
  }
  .steps-canvas .form-steps .form-link {
    font-size: 11px !important;
    margin-top: 12px !important;
  }
  .RegisterPageSection .RegisterPage .form-steps .verify-number .btn {
    top: 6px !important;
  }
  .RegisterPageSection .RegisterPage .verify-number form {
    display: flex !important;
    align-items: center !important;
    padding: 0 0 0 10px !important;
  }
  body .footer {
    background: #040404 !important;
  }
  .SupportSection {
    display: none;
  }
  .SocialSection ul {
    flex-wrap: wrap !important;
  }
  .SocialSection ul li h5 {
    margin-bottom: 4vw !important;
    color: #0088da !important;
    font-size: 3.4666666667vw !important;
    font-weight: 700 !important;
  }
  .SocialSection ul li img {
    height: 16px !important;
    width: auto !important;
    margin: 4px 4px 10px !important;
  }
  .SocialSection ul li:first-child {
    order: 2;
  }
  .SocialSection ul li:last-child {
    order: 3;
  }
  .SocialSection ul li:nth-child(2) {
    order: 1;
  }
  .footer .footer-links ul li a {
    font-size: 10px;
  }
  .footer .sectionDivide .singleSection {
    flex-wrap: wrap !important;
  }
  .slots-section ul li {
    width: 49% !important;
  }
  .WithDrawSection .WithDrawWalletDetails {
    flex-wrap: wrap;
  }
  body .WithDrawSection .WithDrawWalletDetails .deposit-head {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .AccountWithdraw .AccountWithdrawSection,
  .AccountWithdraw .AccountWithdrawSection .RequestSection {
    margin-top: 20px;
  }

  .AccountWithdraw
    .AccountWithdrawSection
    .RequestSection
    .payment-button
    .btn-primary,
  .AccountWithdraw
    .AccountWithdrawSection
    .RequestSection
    .payment-button
    .btn-danger {
    width: 45% !important;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 100% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }
  .ThankyouImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .WhatsappIconFixed img {
    width: 55px;
  }
  .header .headerRight ul li img {
    width: 25px !important;
    max-width: 25px !important;
    margin-right: 3px !important;
  }
  .home-iframe .exchange_iframe {
    padding-top: 0px !important;
  }
  .steps-canvas .form-steps .btn {
    margin: 0 auto;
    margin-top: 0 !important;
  }
  .header .headerRight ul li .balance-amount .WithDrawHeader {
    width: 25px !important;
    height: 25px !important;
  }
  .header .headerRight ul li .balance-amount span {
    padding: 0 5px !important;
    font-size: 9px !important;
  }

  .form-steps img {
    width: 200px;
    text-align: center;
    margin: 0 auto 12px;
  }
  .steps-canvas .form-steps .sportsSection {
    width: 170px !important;
  }
  .header .logo a,
  .footer-logo a {
    width: 85px !important;
  }
  .populaGamesSection ul {
    justify-content: flex-start !important;
  }
  main .populaGamesSection ul li {
    width: 31.4% !important;
  }

  .header .headerRight ul li .deposit-header {
    width: 22px !important;
    height: 22px !important;
  }
  .sports_page .nav .nav-item,
  .custom-tabs .nav .nav-item {
    margin: 0 3px !important;
  }
  .sports_page .nav a.openbets,
  .custom-tabs .nav a.openbets {
    font-size: 12px;
    padding: 6px !important;
  }
  .sport-buttons ul li a .sports-content-text {
    flex-direction: row !important;
    height: 100%;
    align-items: center;
  }
  .sport-buttons ul li a .sports-content-text span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sport-buttons ul li a .sports-content-text img {
    margin-right: 3px;
  }
  .footer .footer-logo a {
    width: 100px !important;
  }
  .header .headerRight ul li .balance-amount img {
    width: 22px !important;
    height: 22px !important;
  }
  .header .searchbar {
    width: 22px !important;
    height: 22px !important;
  }
  .header .searchbar svg {
    font-size: 15px !important;
  }
  .header .menu-btn {
    margin-right: 3px;
    padding: 0 !important;
  }
  .header .headerRight ul li .balance-amount {
    font-size: 13px !important;
    height: 50px !important;
  }
  .afterlogin .headerRight .searchbar {
    margin: 0 !important;
  }
  .sports_page .nav,
  .custom-tabs .nav {
    justify-content: start !important;
  }
  .footer .sectionDivide .singleSection {
    width: 100% !important;
  }
  .SocialMediaSection ul li {
    width: 48% !important;
  }
  .SocialMediaSection ul li a {
    font-size: 10.5px !important;
    padding: 10px 3px !important;
  }
  .SocialMediaSection ul li a img {
    margin-right: 7px !important;
  }
  .main .leftbarSec .games_link ul li,
  .main .leftbarSec .games_link h6 {
    border-bottom: 1px solid #929fac !important;
  }
  .footer .footer-links ul li:after {
    right: -5px !important;
  }
  .footer .footer-links ul li:last-child::after {
    display: none;
  }
  .footer .providers_logo h4,
  .footer .paymentspartner h5 {
    font-size: 16px !important;
    text-align: left !important;
  }
  .footer .paymentspartner ul li img {
    width: 30px !important;
    margin: 0 !important;
  }
  .footer .paymentspartner ul {
    gap: 10px;
  }
  .footer p {
    font-weight: inherit !important;
  }
  .live_tv_box,
  .iframe-div {
    height: 260px !important;
  }
  .live_tv_box .iframe-div iframe {
    height: 260px !important;
  }
  .amount_data {
    flex-wrap: wrap;
    text-align: center;
  }
  .cancel_bonus {
    background: red;
    padding: 4px 2px;
    font-size: 10px;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    text-transform: uppercase;
    justify-content: center !important;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 100% !important;
  }

  .filter-added .dropdown {
    width: 65% !important;
  }
  .live-deal-section ul li {
    width: 49% !important;
    margin: 0.5% !important;
  }
  .live-deal-section ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul li {
    margin: 1% !important;
    width: 23% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info,
  .sports_page .tab-pane .game-listing .match-info {
    display: block !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .match-name,
  .sports_page .tab-pane .game-listing .match-info .match-name {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing,
  .sports_page .tab-pane .game-listing .match-info .listing {
    padding-bottom: 5px !important;
  }
  .betslip_popup .modal-body .stack-win-value .odds div {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing li,
  .sports_page .tab-pane .game-listing .match-info .listing li {
    margin-right: 3px !important;
    width: 100% !important;
  }
  .page-details .game-top-area .date,
  .page-details .game-top-area .range {
    display: none !important;
  }
  .page-details .game-points ul li {
    border-bottom: 1px solid #2f3940 !important;
    display: flex !important;
    padding-left: 0.5rem !important;
  }
  .page-details .game-points ul li .team-name {
    color: var(--headClr) !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    overflow: hidden !important;
    padding: 0 0.25rem 0 0 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: calc(100% - 150px) !important;
  }
  .page-details .game-points ul li .status-btns {
    align-items: center !important;
    display: flex !important;
    width: 140px !important;
  }
  .page-details .game-points ul li .status-btns .info-block:first-child {
    display: none !important;
    margin-left: 0 !important;
  }
  .page-details .game-points ul li .status-btns .info-block {
    display: none !important;
    width: inherit !important;
  }
  .page-details .game-points ul li .status-btns .info-block:nth-child(3),
  .page-details .game-points ul li .status-btns .info-block:nth-child(4) {
    display: flex !important;
    width: 50% !important;
  }
  .page-details .game-points ul li .status-btns .info-block.no:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .banner_sec .banner_imgboxes .img_box {
    flex: 0 0 100% !important;
  }
  .page-details .title span {
    background-color: inherit !important;
  }

  .header .headerRight ul li.head-social {
    display: none !important;
  }
  .header .headerRight ul li.balance {
    display: block !important;
    margin-right: 3px !important;
  }
  .support-header .chat-call-support {
    flex-wrap: wrap;
  }
  .support-header .chat-call-support .chat-box {
    width: 90% !important;
    padding: 25px 0 !important;
  }
  .support-header .chat-call-support .chat-box ul {
    justify-content: start !important;
  }
  .support-header .chat-call-support .chat-box p {
    margin-bottom: 0 !important;
    font-size: 20px !important;
  }
  .support-header .chat-call-support .chat-box h2 {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .support-header .chat-call-support .chat-box .email-section p {
    font-size: 13px !important;
  }
  .support-header .chat-call-support .chat-box .email-section a {
    font-size: 18px !important;
    font-weight: 700;
  }
  .support-header .chat-call-support .chat-box:nth-child(2) {
    border-bottom: 1px solid #a5a5a5;
    border-top: 1px solid #a5a5a5;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .password-note {
    margin-bottom: 12px;
  }
  .password-note ul li {
    font-size: 12px;
  }
}

@media only screen and (max-width: 366px) {
  main .populaGamesSection ul li {
    width: 30.33% !important;
  }
}

@media only screen and (max-width: 991.98px) {
  .WithDrawSection .WithDrawWalletDetails .deposit-head {
    width: 50% !important;
  }
}

/* @media only screen and (device-width: 320px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    max-height: calc(100vh - 105px) !important;
    height: calc(100vh - 105px) !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    height: calc(100vh - 105px) !important;
    max-height: calc(100vh - 105px) !important;
  }
} */
body {
  background-color: #4e4e4e !important;
}
.RegisterPageSection {
  position: relative;
  margin: 200px auto 65px;
  max-width: 1200px;
  padding: 1.875rem;
  border: 0.0625rem solid #0088da;
  border-width: 0.375rem;
  text-align: center;
  border-image: linear-gradient(to bottom, #0088da 0, #28aeff 100%);
  border-image-slice: 1;
}
.RegisterPageSection .RegisterPage {
  display: flex;
}
.RegisterPageSection .RegisterPage .login-modal {
  width: 30%;
  margin-right: 3%;
}
.RegisterPageSection .RegisterPage .signupimg {
  position: relative;
  width: 65%;
}
.RegisterPageSection .RegisterPage span.top-heading {
  color: #ffdf18;
  font-size: 18px;
  width: 100%;
  line-height: 35px;
  display: block;
  border-bottom: 1px solid #ffdf18;
  margin-bottom: 15px;
}
.RegisterPageSection .RegisterPage .form-steps label {
  color: var(--whiteClr);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  display: block;
  margin-bottom: 0.4375rem;
  white-space: nowrap !important;
}
.RegisterPageSection .RegisterPage .form-steps input {
  color: var(--whiteClr) !important;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding: 0.6875rem;
  border: 0.125rem solid transparent;
  border-radius: 3px !important;
  background: rgba(51, 51, 51, 0.3) !important;
  margin-bottom: 0;
}
.RegisterPageSection .RegisterPage .form-steps input::placeholder {
  color: #7d7d7d !important;
}
.RegisterPageSection .RegisterPage .form-steps .invalid-feedback {
  color: #f35e5e;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  padding-top: 3px;
  margin-top: 0;
}
.RegisterPageSection .RegisterPage .form-steps input::placeholder {
  color: var(--whiteClr);
}
body input::-webkit-autofil,
body input::-webkit-autofill:hover,
body input:-webkit-autofill:focus,
body input:-webkit-autofill:active {
  box-shadow: rgba(51, 51, 51, 0.3) inset !important;
  -webkit-text-fill-color: #fff !important;
}
.RegisterPageSection .RegisterPage .form-steps .password-note ul li {
  color: var(--whiteClr);
}
.RegisterPageSection .RegisterPage .form-steps .verify-number .btn {
  position: absolute;
  top: 26px;
  right: 0;
  background: #0078c1;
  color: var(--whiteClr);
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
  border-color: #0078c1;
  border-radius: 3px;
}
.RegisterPageSection .RegisterPage .form-steps .verify-number .btn:hover {
  background: #036baa !important;
  border-color: #036baa !important;
}
.RegisterPageSection .RegisterPage .form-steps .submit-btn {
  background: linear-gradient(
    to right,
    #facd04 0,
    #fbc103 15%,
    #fbc103 30%,
    #facd04 55%,
    #facd04 100%
  );
  width: 100%;
  margin: 0 auto;
  border-color: #fbc103;
  border-radius: 4px;
}
.RegisterPageSection .RegisterPage .form-steps .submit-btn:hover {
  background: linear-gradient(
    to right,
    #facd04 0,
    #fbc103 15%,
    #fbc103 30%,
    #facd04 55%,
    #facd04 100%
  ) !important;
  color: var(--whiteClr) !important;
  text-shadow: 0 1px 3px #967b02;
  box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
    0 4px 4px 1px rgba(6, 8, 15, 0.1), 0 8px 8px 1px rgba(6, 8, 15, 0.1),
    0 16px 16px 1px rgba(6, 8, 15, 0.1), 0 32px 32px 1px rgba(6, 8, 15, 0.1),
    inset 0 0 3px 0 #ffdd8f;
}
.offcanvas-header {
  background-color: #222222 !important;
  height: 50px;
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  color: var(--whiteClr) !important;
}
.offcanvas-header span.top-heading {
  color: var(--whiteClr) !important;
}
.offcanvas-body {
  background-color: #111111 !important;
  height: 50px;
  color: var(--whiteClr) !important;
}
body .offcanvas-body .form-steps input {
  background-color: #181818 !important;
  margin-bottom: 0 !important;
  border-radius: 4px 4px 0 0 !important;
  border: 0 !important;
  color: var(--whiteClr) !important;
}
body .offcanvas-body .form-steps input::placeholder {
  color: var(--whiteClr) !important;
}
body .offcanvas-body .form-steps a.form-link {
  display: block !important;
  padding: 6px !important;
  border: 1px solid #0088da !important;
  border-radius: 4px !important;
  color: #0088da !important;
  text-decoration: none !important;
  margin-top: 15px;
}
body .offcanvas-body .form-steps .btn {
  background: #0088da;
  color: var(--whiteClr);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  width: 100%;
  border: 0;
}
body .offcanvas-body .form-steps .btn:hover {
  background: #0088da !important;
  color: var(--whiteClr) !important;
}
body .offcanvas-body .form-steps .sign-up {
  text-decoration: none !important;
  font-size: 13px !important;
}
body .offcanvas-body .form-steps .sign-up a {
  color: #ffdf1a !important;
  font-size: 13px !important;
  padding-left: 3px;
}
.SupportSection ul {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(85, 85, 85, 0.3);
  padding-bottom: 15px;
}
.SupportSection ul li {
  display: flex;
}
.SupportSection ul li img {
  width: 60px;
  height: 60px;
  margin-right: 1rem;
}
.SupportSection ul li p {
  font-size: 16px;
  line-height: 40px;
  color: #dedede;
  margin-bottom: 0;
}
.SupportSection ul li p.smallp {
  margin-bottom: 0.625rem;
  color: #999;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0.625rem;
}
.SupportSection ul li a {
  margin-right: 5px;
  color: #fff;
}
.SupportSection ul li img.ApkImg {
  width: auto !important;
}
.SocialSection {
  padding-top: 20px;
}
.SocialSection ul {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(85, 85, 85, 0.3);
  padding-bottom: 15px;
}
.SocialSection ul li h5 {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0.625rem;
  color: rgba(255, 255, 255, 0.8);
}
.SocialSection ul li img {
  filter: saturate(0) brightness(90%) contrast(50%);
  margin-right: 1.25rem;
  width: 22px;
}
.SocialSection ul li img.footerwrap {
  height: 1.5625rem;
  width: auto;
}
.FooterContent {
  border-bottom: 1px solid rgba(85, 85, 85, 0.3);
  padding: 0.9375rem 0;
}
.FooterContent h4 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8125rem;
  font-weight: 700;
}
.FooterContent p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 0.75rem !important;
  line-height: 1.2 !important;
}
.footer .sectionDivide .singleSection {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.footer .footer-links {
  background-color: transparent !important;
  margin: 0 !important;
}
.footer .footer-links ul li a {
  padding: 0 0.9375rem !important;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.footer .footer-links ul li:after {
  display: none;
}
.footer .footer-links ul li {
  margin: 0 !important;
}
.LoginModal .modal-header {
  position: relative;
  height: 60px;
  border-bottom: 1px solid #41b8ff;
  color: #fff;
  background: #0088da;
}
.LoginModal .modal-header .modal-title {
  text-align: left !important;
  color: var(--whiteClr) !important;
  font-size: 18px;
  left: 53% !important;
}
.LoginModal .modal-content {
  width: 80%;
}
.LoginModal .modal-content .modal-body {
  padding: 30px 50px;
}
.LoginModal .modal-content .modal-body label {
  text-align: left;
  display: block;
  margin-bottom: 6px;
}
.LoginModal .modal-content .modal-body input {
  color: #0088da !important;
  font-weight: 600;
  height: 40px;
  padding: 0 10px 0 45px;
  border: 1px solid #adacb1;
  border-radius: 3px !important;
}
.LoginModal .modal-content .modal-body input:-webkit-autofill,
.LoginModal .modal-content .modal-body input:-webkit-autofill:hover,
.LoginModal .modal-content .modal-body input:-webkit-autofill:focus,
.LoginModal .modal-content .modal-body input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
  -webkit-text-fill-color: #0088da !important;
}
.LoginModal .modal-content .modal-body input::placeholder {
  color: #777;
  font-weight: normal;
}
.LoginModal .modal-content .modal-body img.InputImg {
  position: absolute;
  left: 10px;
  top: 35px;
}
.LoginModal .modal-content .modal-body img.InputImg1 {
  position: absolute;
  left: 10px;
  top: 35px;
}

.LoginModal .modal-content .modal-body .form-steps .form-link {
  color: #474747 !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.LoginModal .modal-content .modal-body .form-steps .form-link:hover {
  color: #0088da !important;
}
.LoginModal .modal-content .modal-body .form-steps button {
  width: 100%;
  background: linear-gradient(
    to right,
    #facd04 0,
    #fbc103 15%,
    #fbc103 30%,
    #facd04 55%,
    #facd04 100%
  );
  font-weight: normal;
  text-transform: none;
  border-radius: 4px;
  height: 40px;
  border: 0;
}
.LoginModal .modal-content .modal-body .form-steps button:hover {
  background: linear-gradient(
    to right,
    #facd04 0,
    #fbc103 15%,
    #fbc103 30%,
    #facd04 55%,
    #facd04 100%
  ) !important;
  text-shadow: 0 1px 3px #967b02;
  box-shadow: 0 2px 2px 1px rgba(6, 8, 15, 0.1),
    0 4px 4px 1px rgba(6, 8, 15, 0.1), 0 8px 8px 1px rgba(6, 8, 15, 0.1),
    0 16px 16px 1px rgba(6, 8, 15, 0.1), 0 32px 32px 1px rgba(6, 8, 15, 0.1),
    inset 0 0 3px 0#ffdd8f;
  color: var(--whiteClr);
}
.LoginModal .modal-content .modal-body .form-steps .sign-up {
  color: var(--blackClr) !important;
  font-weight: normal;
}
.LoginModal .modal-content .modal-body .form-steps .sign-up.signupsection {
  text-decoration: none;
}
.LoginModal .modal-content .modal-body .form-steps .sign-up.signupsection a {
  text-decoration: underline;
  color: var(--blackClr);
  font-size: 13px;
}
.LoginModal
  .modal-content
  .modal-body
  .form-steps
  .sign-up.signupsection
  a:hover {
  color: #0088da !important;
}

.ForgotPasswordSection input {
  color: #0088da !important;
  font-weight: 600;
  height: 40px;
  padding: 0 10px 0 45px !important;
  border: 1px solid #adacb1 !important;
  border-radius: 3px !important;
}
